/**
 * article模块接口列表
 */

import base from "./base"; // 导入接口域名列表
import axios from "../request/http"; // 导入http中创建的axios实例
import store from "../store";
//import qs from 'qs'; // 根据需求是否导入qs模块,qs模块主要是为了对post请求时提交的数据进行一个qs序列化的处理,如果不进行处理 后端拿不到数据
//import { Search } from 'vant';

const search = {
  articleDetail(id, params) {
    return axios.get(`${base.sq}/topic/${id}`, {
      params: params
    });
  },
  login(params) {
    return axios.post(`${base.fm2}/be/v1/account/login`, JSON.stringify(params));
  },
  updatapassword(params) {
    return axios.put(`${base.fm2}/be/v1/account/password`, JSON.stringify(params));
  },






  //订单管理
  addSalesList(params) {
    return axios.post(`${base.fm2}/be/v1/goodsOrders`, JSON.stringify(params));
  },
  getSalesList(params) {
    return axios.get(`${base.fm2}/be/v1/goodsOrders`, {
      params: params
    });
  },
  //订单商品详情
  getSalesListDetail(id) {
    return axios.get(`${base.fm2}/be/v1/goodsOrders/${id}/goods`);
  },
  //运单查询接口
  getSalesListExpress(orderId) {
    return axios.get(`${base.fm2}/be/v1/goodsOrders/${orderId}/express`);
  },












  //订单发货
  delivery(params, id) {
    return axios.post(`${base.fm2}/be/v1/goodsOrders/${id}/express`, JSON.stringify(params));
  },
  //修改发货信息
  updataDelivery(params, id) {
    return axios.put(`${base.fm2}/be/v1/expresses/${id}`, JSON.stringify(params));
  },
  //excel表格操作
  //订单导入模板下载
  getTemplate(params) {
    return axios.get(`${base.fm2}/be/v1/excel/goodsOrder/template`, {
      params: params,
      responseType: 'blob'
    });
  },
  //下单接口
  goodsOrder(params) {
    return axios.post(`${base.fm2}/be/v1/goodsOrders/batchHandle`, JSON.stringify(params));
  },
  //下单批次列表
  orderBatches(params) {
    return axios.get(`${base.fm2}/be/v1/excel/goodsOrder/batches`, {
      params: params
    });
  },
  //导出供应商订单
  orderRecord(Id) {
    return axios.get(`${base.fm2}/be/v1/excel/goodsOrder/export/${Id}`, {
      responseType: 'blob'
    });
  },
  //导出货物信息
  goodsOut(params) {
    return axios.get(`${base.fm2}/be/v1/excel/goodsOrder/export`, {
      params: params,
      responseType: 'blob'
    });
  },
  //发送短信接口
  sendMessage(State, params) {
    return axios.post(`${base.fm2}/be/v1/goodsOrders/sms/${State}`, JSON.stringify(params));
  },


  //物流导入模板下载
  getLogisticsTemplate(params) {
    return axios.get(`${base.fm2}/be/v1/excel/express/template`, {
      params: params,
      responseType: 'blob'
    });
  },







  //物流信息
  getLogistics(params) {
    return axios.get(`${base.fm2}/be/v1/expresses`, {
      params: params
    });
  },
  updataLogistics(params) {
    return axios.put(`${base.fm2}/be/v1/expresses/{id}`, JSON.stringify(params));
  },







  //基础数据
  //品类
  addCategory(params) {
    return axios.post(`${base.fm2}/be/v1/categories`, JSON.stringify(params));
  },
  getCategoryList(params) {
    return axios.get(`${base.fm2}/be/v1/categories`, {
      params: params
    });
  },
  updataCategory(id, params) {
    return axios.put(
      `${base.fm2}/be/v1/categories/${id}`,
      JSON.stringify(params)
    );
  },
  updataStatuCategory(id, valid) {
    return axios.put(`${base.fm2}/be/v1/categories/${id}/${valid}`);
  },
  //---->品类下的规格
  getCategoryMaterialList(id, params) {
    return axios.get(`${base.fm2}/be/v1/categories/${id}/specs`, {
      params: params
    });
  },
  //---->品类下的型号
  getCategoryModelList(id, params) {
    return axios.get(`${base.fm2}/be/v1/categories/${id}/types`, {
      params: params
    });
  },

  //型号
  addModel(params) {
    return axios.post(`${base.fm2}/be/v1/types`, JSON.stringify(params));
  },
  getModelList(params) {
    return axios.get(`${base.fm2}/be/v1/types`, {
      params: params
    });
  },
  updataModel(id, params) {
    return axios.put(`${base.fm2}/be/v1/types/${id}`, JSON.stringify(params));
  },
  updataStatuModel(id, valid) {
    return axios.put(`${base.fm2}/be/v1/types/${id}/${valid}`);
  },
  //规格
  addMaterial(params) {
    return axios.post(`${base.fm2}/be/v1/specs`, JSON.stringify(params));
  },
  getMaterialList(params) {
    return axios.get(`${base.fm2}/be/v1/specs`, {
      params: params
    });
  },
  updataMaterial(id, params) {
    return axios.put(`${base.fm2}/be/v1/specs/${id}`, JSON.stringify(params));
  },
  updataStatuMaterial(id, valid) {
    return axios.put(`${base.fm2}/be/v1/specs/${id}/${valid}`);
  },
  //款式
  addStyle(params) {
    return axios.post(`${base.fm2}/be/v1/designs`, JSON.stringify(params));
  },
  getStyleList(params) {
    return axios.get(`${base.fm2}/be/v1/designs`, {
      params: params
    });
  },
  updataStyle(id, params) {
    return axios.put(`${base.fm2}/be/v1/designs/${id}`, JSON.stringify(params));
  },
  updataStatuStyle(id, valid) {
    return axios.put(`${base.fm2}/be/v1/designs/${id}/${valid}`);
  },
  //快递公司
  addCourier(params) {
    return axios.post(`${base.fm2}/be/v1/expressCos`, JSON.stringify(params));
  },
  getCourierList(params) {
    return axios.get(`${base.fm2}/be/v1/expressCos`, {
      params: params
    });
  },
  updataCourier(id, params) {
    return axios.put(
      `${base.fm2}/be/v1/expressCos/${id}`,
      JSON.stringify(params)
    );
  },
  updataStatuCourier(id, valid) {
    return axios.put(`${base.fm2}/be/v1/expressCos/${id}/${valid}`);
  },
  //来源渠道
  addSources(params) {
    return axios.post(`${base.fm2}/be/v1/channels`, JSON.stringify(params));
  },
  getSourcesList(params) {
    return axios.get(`${base.fm2}/be/v1/channels`, {
      params: params
    });
  },
  updataSources(id, params) {
    return axios.put(
      `${base.fm2}/be/v1/channels/${id}`,
      JSON.stringify(params)
    );
  },
  updataStatuSources(id, valid) {
    return axios.put(`${base.fm2}/be/v1/channels/${id}/${valid}`);
  },
  //关联资源品类
  addAssociated(params) {
    return axios.post(
      `${base.fm2}/be/v1/channelCategories`,
      JSON.stringify(params)
    );
  },
  getAssociatedList(params) {
    return axios.get(`${base.fm2}/be/v1/channelCategories`, {
      params: params
    });
  },
  updataAssociated(id, params) {
    return axios.put(
      `${base.fm2}/be/v1/channelCategories/${id}`,
      JSON.stringify(params)
    );
  },
  updataStatuAssociated(id, valid) {
    return axios.put(`${base.fm2}/be/v1/channelCategories/${id}/${valid}`);
  },
  //货品
  addGoods(params) {
    return axios.post(`${base.fm2}/be/v1/stocks`, JSON.stringify(params));
  },
  getGoodsList(params) {
    return axios.get(`${base.fm2}/be/v1/stocks`, {
      params: params
    });
  },
  updataGoods(id, params) {
    return axios.put(`${base.fm2}/be/v1/stocks/${id}`, JSON.stringify(params));
  },
  updataStatuGoods(id, valid) {
    return axios.put(`${base.fm2}/be/v1/stocks/${id}/${valid}`);
  },
  //活动
  addActivity(params) {
    return axios.post(
      `${base.fm2}/be/v1/buyActivities`,
      JSON.stringify(params)
    );
  },
  getActivityList(params) {
    return axios.get(`${base.fm2}/be/v1/buyActivities`, {
      params: params
    });
  },
  updataActivity(id, params) {
    return axios.put(
      `${base.fm2}/be/v1/buyActivities/${id}`,
      JSON.stringify(params)
    );
  },
  updataStatuActivity(id, valid) {
    return axios.put(`${base.fm2}/be/v1/buyActivities/${id}/${valid}`);
  },






  //角色权限
  //添加角色
  //获取角色列表
  //修改角色
  //启用/作废角色
  addRole(params) {
    return axios.post(
      `${base.fm2}/be/v1/roles`,
      JSON.stringify(params)
    );
  },
  getRoleList(params) {
    return axios.get(`${base.fm2}/be/v1/roles`, {
      params: params
    });
  },
  updataRole(id, params) {
    return axios.put(
      `${base.fm2}/be/v1/roles/${id}`,
      JSON.stringify(params)
    );
  },
  updataStatuRole(id, valid) {
    return axios.put(`${base.fm2}/be/v1/roles/${id}/${valid}`);
  },
  //获取角色下的权限树
  getRoleResources(id) {
    return axios.get(`${base.fm2}/be/v1/roles/${id}/resources`);
  },
  //更新角色下权限树
  updataRoleResources(id, params) {

    return axios.put(
      `${base.fm2}/be/v1/roles/${id}/resources`,
      JSON.stringify(params)
    );
  },

  //管理员操作
  addAccount(params) {
    return axios.post(
      `${base.fm2}/be/v1/admins`,
      JSON.stringify(params)
    );
  },
  getAccountList(params) {
    return axios.get(`${base.fm2}/be/v1/admins`, {
      params: params
    });
  },
  updataAccount(id, params) {
    return axios.put(
      `${base.fm2}/be/v1/admins/${id}`,
      JSON.stringify(params)
    );
  },
  updataStatuAccount(id, valid) {
    return axios.put(`${base.fm2}/be/v1/admins/${id}/${valid}`);
  },
  resetPassword(id, valid) {
    return axios.put(`${base.fm2}/be/v1/admins/${id}/resetPsw`);
  },



};

export default search;
