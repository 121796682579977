<template>
  <!-- 付款记录 -->
  <div class="main">
    <div class="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
        <el-form-item label="来源渠道">
          <el-select placeholder="来源渠道" v-model="formInline.region">
            <el-option label="全部" value="1"></el-option>
            <el-option label="OPPO主题商店" value="2"></el-option>
            <el-option label="华为相册" value="3"></el-option>
            <el-option label="华为主题商店" value="4"></el-option>
            <el-option label="VIVO主题商店" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联资源品类">
          <el-select placeholder="品类" v-model="formInline.region">
            <el-option label="全部" value="1"></el-option>
            <el-option label="主题" value="2"></el-option>
            <el-option label="壁纸" value="3"></el-option>
            <el-option label="字体" value="4"></el-option>
            <el-option label="铃声" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input placeholder="型号" v-model="formInline.user"></el-input>
        </el-form-item>
        <el-form-item label="用户呢称">
          <el-input placeholder="用户呢称" v-model="formInline.user"></el-input>
        </el-form-item>
        <el-form-item label="用户手机号">
          <el-input placeholder="用户手机号" v-model="formInline.user"></el-input>
        </el-form-item>
        <el-form-item label="用户邮箱">
          <el-input placeholder="用户邮箱" v-model="formInline.user"></el-input>
        </el-form-item>
        <el-form-item label="付款时间">
          <el-date-picker
            :picker-options="pickerOptions"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="value2"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSubmit" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSubmit" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="button">
      <el-button @click="Submitin" size="small" type="primary">导入付款记录</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="来源渠道" prop="date" width="150"></el-table-column>
        <el-table-column label="关联资源品类" prop="name" width="120"></el-table-column>
        <el-table-column label="名称" prop="province" width="120"></el-table-column>
        <el-table-column label="编号" prop="city" width="120"></el-table-column>
        <el-table-column label="支付金额" prop="address" width="300"></el-table-column>
        <el-table-column label="用户ID" prop="zip" width="120"></el-table-column>
        <el-table-column label="付款时间" prop="zip" width="120"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" size="small" type="text">查看</el-button>
            <el-button size="small" type="text">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage4"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          :total="40"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="提示">
        <el-table :data="tableData2" border height="250" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="订单编号" prop="date" width="180"></el-table-column>
          <el-table-column label="品类" prop="name" width="180"></el-table-column>
          <el-table-column label="规格" prop="address"></el-table-column>
          <el-table-column label="款式" prop="address"></el-table-column>
          <el-table-column label="型号" prop="address"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible2" title="提示">
        <el-upload
          :beforeUpload="beforeUpload"
          :file-list="filesUpload"
          :on-change="removeLastFile"
          :on-error="Onerror"
          accept=".xls, .xlsx"
          action="https://jsonplaceholder.typicode.com/posts/"
          class="upload-demo"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip" style="color:red">
            只支持Excel文件的导入,格式包括:xls, xlsx
            <br />单个导入的文件大小不能超过4M
          </div>
        </el-upload>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible3" title="提示" width="30%">
        <h2>即将导出xx条订单的xx条商品信息</h2>
        <el-checkbox style="margin-top:2rem;font-size:2rem" v-model="checked">将商品所属订单订单状态标记为生产中</el-checkbox>
        <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button @click="dialogVisible3 = false" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { regionDataPlus } from 'element-china-area-data'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      formInline: {
        user: '',
        region: '',
        workarea: []
      },
      tableData: [
        {
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        },
        {
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        },
        {
          date: '2016-05-08',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        },
        {
          date: '2016-05-06',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        },
        {
          date: '2016-05-07',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      options: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      filesUpload: [],
      currentPage4: 1,
      checked: true,
      tableData2: [
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }
      ]
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!')
    },
    handleClick(row) {
      console.log(row)
      this.dialogVisible = true
    },
    Submitin() {
      this.dialogVisible2 = true
    },
    Submitout() {
      this.dialogVisible3 = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleChange(value) {
      console.log(value)
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    //定义导出Excel表格事件
    exportExcel() {
      // /* 从表生成工作簿对象 */
      // let wb = XLSX.utils.table_to_book(document.querySelector(".el-table"));
      // /* 获取二进制字符串作为输出 */
      // let wbout = XLSX.write(wb, {
      //   bookType: "xlsx",
      //   bookSST: true,
      //   type: "array"
      // });
      // try {
      //   FileSaver.saveAs(
      //     //Blob 对象表示一个不可变、原始数据的类文件对象。
      //     //Blob 表示的不一定是JavaScript原生格式的数据。
      //     //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
      //     //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
      //     new Blob([wbout], { type: "application/octet-stream" }),
      //     //设置导出文件名称
      //     "导出订单表.xlsx"
      //   );
      // } catch (e) {
      //   if (typeof console !== "undefined") console.log(e, wbout);
      // }
      // return wbout;
    }
  },
  mounted() {
    this.options = regionDataPlus
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
</style>
