<template>
  <div class="bgc" style="overflow:hidden">
    <el-container>
      <div class="tip">
        <p>衍生品订单管理后台</p>
      </div>
      <el-form :model="ruleForm" :rules="rules" class="demo-ruleForm" ref="ruleForm" status-icon>
        <el-form-item label prop="username">
          <el-input
            @keyup.native.enter="submitForm()"
            autocomplete="off"
            placeholder="用户名"
            type="text"
            v-model="ruleForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item label prop="password">
          <el-input
            @keyup.native.enter="submitForm()"
            autocomplete="off"
            placeholder="密码"
            type="password"
            v-model="ruleForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="submitForm()" type="primary">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        username: '',
        password: ''
      },

      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        pass: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    // let metaTag = document.createElement('meta')
    // metaTag.name = 'viewport'
    // metaTag.content =
    //   'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no'
    // document.getElementsByTagName('head')[0].appendChild(metaTag)
  },
  components: {},
  methods: {
    submitForm() {
      let _this = this
      this.$api.search.login(this.ruleForm).then(res => {
        if (res.status == 200) {
          if (res.data.code == 0) {
            console.log(res, '登录成功')
            this.$store.commit('token', res.data.data.token)
            this.$store.commit('username', res.data.data.username)
            this.$store.commit('roleName', res.data.data.roleName)
            let store = {}
            for (let el of res.data.data.resourceCodeList) {
              store[el] = true
            }
            this.$store.commit('resourceCodeList', store)
            _this.$router.push({
              path: '/Layout/ListAudit'
            })
          } else if (res.data.code == 1000) {
            this.$message({
              message: '账户名错误',
              type: 'error',
              duration: 1000
            })
          } else if (res.data.code == 1001) {
            this.$message({
              message: '密码错误',
              type: 'error',
              duration: 1000
            })
          } else {
            this.$message({
              message: '系统忙,请重试',
              type: 'error',
              duration: 1000
            })
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.bgc {
  height: 100vh;
  width: 100vw;
  background-color: rgba(101, 140, 179, 1);
  background: -webkit-linear-gradient(
    top,
    rgba(101, 140, 179, 1),
    rgba(157, 181, 119, 1)
  );
}

.bgc .el-container {
  width: 32rem;
  height: 21rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10.5rem;
  margin-left: -16rem;
  padding: 2.5rem;
  border-radius: 0.5rem;
  text-align: center;
  background-color: #fff;
  font-size: 1.6rem;
}

.bgc .el-container .el-form {
  width: 27rem;
  margin: 0 auto;
}

.tip {
  position: absolute;
  width: 100%;
  top: -10rem;
  left: 0;
  user-select: none;
}

.tip p {
  font-size: 3.4rem;
  color: #fff;
}
</style>
