<template>
  <!-- 数据统计 -->
  <div>
    <div class="form">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="来源渠道">
          <el-select v-model="formInline.region" placeholder="来源渠道">
            <el-option label="全部" value="1"></el-option>
            <el-option label="OPPO主题商店" value="2"></el-option>
            <el-option label="华为相册" value="3"></el-option>
            <el-option label="华为主题商店" value="4"></el-option>
            <el-option label="VIVO主题商店" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计时间">
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="chart">
      <div class="sum">
        <div class="title">
          <div></div>
          <h2>每日订单总数&付款数量</h2>
          <el-button size="small" type="primary" @click="exportExcel"
            >导出</el-button
          >
        </div>
        <div id="myChart1"></div>
        <div class="table">
          <el-table :data="tableData1" border height="300" stripe>
            <el-table-column prop="date" label="日期" width="150">
            </el-table-column>
            <el-table-column prop="name" label="订单总数"> </el-table-column>
            <el-table-column prop="province" label="付款数量">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="produce">
        <div class="title">
          <div></div>
          <h2>每日开始生产的订单数量</h2>
          <el-button size="small" type="primary" @click="exportExcel"
            >导出</el-button
          >
        </div>
        <div id="myChart2"></div>
        <div class="table">
          <el-table :data="tableData2" border height="300" stripe>
            <el-table-column prop="date" label="日期" width="150">
            </el-table-column>
            <el-table-column prop="name" label="开始生产订单数量">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="put">
        <div class="title">
          <div></div>
          <h2>每日发货订单数</h2>
          <el-button size="small" type="primary" @click="exportExcel"
            >导出</el-button
          >
        </div>
        <div id="myChart3"></div>
        <div class="table">
          <el-table :data="tableData3" border height="300" stripe>
            <el-table-column prop="date" label="日期" width="150">
            </el-table-column>
            <el-table-column prop="name" label="发货订单数量">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        user: "",
        region: "",
        workarea: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      value2: "",
      tableData1: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          province: "上海"
        }
      ],
      tableData2: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          province: "上海"
        }
      ],
      tableData3: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          province: "上海"
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          province: "上海"
        }
      ]
    };
  },
  mounted() {
    this.drawLine();
    this.drawLine2();
    this.drawLine3();
  },
  updated() {
    this.drawLine();
    this.drawLine2();
    this.drawLine3();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart1"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: ""
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["领取记录", "购买记录"]
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "领取记录",
            type: "line",
            stack: "总量",
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: "购买记录",
            type: "line",
            stack: "总量",
            data: [220, 182, 191, 234, 290, 330, 310]
          }
        ]
      });
    },
    drawLine2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart2"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["生产订单数"]
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "生产订单数",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line"
          }
        ]
      });
    },
    drawLine3() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart3"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["发货订单数"]
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "发货订单数",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line"
          }
        ]
      });
    },
    //定义导出Excel表格事件
    exportExcel() {
      // /* 从表生成工作簿对象 */
      // let wb = XLSX.utils.table_to_book(document.querySelector(".el-table"));
      // /* 获取二进制字符串作为输出 */
      // let wbout = XLSX.write(wb, {
      //   bookType: "xlsx",
      //   bookSST: true,
      //   type: "array"
      // });
      // try {
      //   FileSaver.saveAs(
      //     //Blob 对象表示一个不可变、原始数据的类文件对象。
      //     //Blob 表示的不一定是JavaScript原生格式的数据。
      //     //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
      //     //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
      //     new Blob([wbout], { type: "application/octet-stream" }),
      //     //设置导出文件名称
      //     "导出订单表.xlsx"
      //   );
      // } catch (e) {
      //   if (typeof console !== "undefined") console.log(e, wbout);
      // }
      // return wbout;
    }
  }
};
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.chart {
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    margin-bottom: 2rem;
    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  .table {
    width: 48%;
    padding: 1rem;
    box-sizing: border-box;
    height: 30rem;

    /deep/ .cell {
      text-align: center;
    }
  }

  .sum {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 2rem;
  }
  .produce {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 2rem;
  }
  .put {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 2rem;
  }
  #myChart1 {
    width: 48%;
    height: 30rem;
    padding: 1rem;
    box-sizing: border-box;
  }
  #myChart2 {
    width: 48%;
    height: 30rem;
    padding: 1rem;
    box-sizing: border-box;
  }
  #myChart3 {
    width: 48%;
    height: 30rem;
    padding: 1rem;
    box-sizing: border-box;
  }
}
</style>
