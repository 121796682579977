import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    persistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          // 只储存state中的assessmentData
          token: val.token,
          username: val.username,
          roleName: val.roleName,
          resourceCodeList: val.resourceCodeList
        };
      }
    })
  ],

  state: {
    token: "",
    username: ""
  },
  mutations: {
    token(state, val) {
      state.token = val;
    },
    username(state, val) {
      state.username = val;
    },
    roleName(state, val) {
      state.roleName = val;
    },
    resourceCodeList(state, val) {
      state.resourceCodeList = val;
    },
  },
  actions: {},
  modules: {}
});
