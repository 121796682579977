<template>
  <!-- 角色权限 -->
  <div class="main">
    <div class="button">
      <el-button
        @click="Submitin"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.role_add"
      >新增</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="角色编号" prop="id"></el-table-column>
        <el-table-column label="角色名称" prop="name"></el-table-column>
        <el-table-column
          :filter-method="filterTag"
          :filters="[{ text: '可用', value: true }, { text: '作废', value: false }]"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.valid === true ? 'success' : 'danger'"
              disable-transitions
            >{{scope.row.valid === true?"可用":"作废"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="菜单权限设置">
          <template slot-scope="scope">
            <el-button
              @click="handleClick1(scope.row)"
              size="small"
              type="text"
              v-if="$store.state.resourceCodeList.role_get_resources"
            >设置</el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="167">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              size="small"
              type="primary"
              v-if="$store.state.resourceCodeList.role_update"
            >修改</el-button>

            <el-button
              @click="updataStatuRole(scope.row)"
              size="small"
              type="danger"
              v-if="scope.row.valid === true && $store.state.resourceCodeList.role_valid"
            >作废</el-button>
            <el-button
              @click="updataStatuRole(scope.row)"
              size="small"
              type="success"
              v-if="scope.row.valid === false && $store.state.resourceCodeList.role_valid"
            >激活</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage4"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible3" title="权限设置">
        <el-tree
          :data="ResourcesData"
          :default-checked-keys="treekey"
          :default-expanded-keys="[]"
          :props="defaultProps"
          default-checked-all
          node-key="id"
          ref="tree_right"
          show-checkbox
        ></el-tree>
        <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button @click="handletreeUpdata" type="primary">保 存</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible2"
        title="新增角色"
        width="50rem"
      >
        <div class="form1">
          <div>
            <el-form :model="form" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="角色名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible2 = false">取 消</el-button>
            <el-button @click="addRole" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="修改品类">
        <div class="form1">
          <div>
            <el-form :model="form1" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="角色编号">
                <el-input readonly v-model="form1.id"></el-input>
              </el-form-item>
              <el-form-item label="角色名称">
                <el-input v-model="form1.name"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="updataRole" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        user: '',
        region: '',
        workarea: []
      },
      form: {
        name: ''
      },
      form1: {
        id: '',
        name: ''
      },
      updataId: '',
      updatapage: {
        page: 1,
        count: 10
      },
      tableData: [],
      total: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      filesUpload: [],
      currentPage4: 1,
      checked: true,
      tableData2: [],
      ResourcesData: ['订单管理', '物流信息', '基础数据', '账号角色权限'],
      defaultProps: {
        children: 'childList',
        label: 'label'
      },
      userid: '',
      treekey: [],
      loading: ''
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!')
    },
    handleClick1(row) {
      console.log(row)
      console.log(this.ResourcesData)
      this.openFullScreen2()
      this.getRoleResources(row)
      this.userid = row.id
    },
    Submitin() {
      this.form = { name: '' }
      this.form1 = { name: '' }
      this.dialogVisible2 = true
    },
    handletreeUpdata() {
      this.openFullScreen2()
      this.treekey = this.$refs.tree_right.getCheckedKeys()
      this.updataRoleResources()
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleChange(value) {
      console.log(value)
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },

    //--------分割--------
    //接口方法定义
    addRole() {
      console.log('新增角色')
      this.$api.search.addRole(this.form).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '新增成功',
            type: 'success',
            duration: 500
          })
          this.getRoleList()
          this.dialogVisible2 = false
        } else {
          this.$message({
            message: '新增失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    getRoleList() {
      console.log('获取角色列表')
      this.$api.search.getRoleList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total = data.total
          this.tableData = data.list
          console.log(this.tableData)
        }
      })
    },
    //翻页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.updatapage.page = val
      this.getRoleList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage.count = val
      this.getRoleList()
    },

    updataRole() {
      console.log('更新角色')
      this.$api.search.updataRole(this.updataId, this.form1).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 500
          })
          this.getRoleList()
          this.dialogVisible = false
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    updataStatuRole(row) {
      console.log('更新角色状态')
      let valid
      if (row.valid == true) {
        valid = false
      } else {
        valid = true
      }
      this.$api.search.updataStatuRole(row.id, valid).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          row.valid = valid
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    updataRoleResources(row) {
      console.log('更新角色状态树状态')
      this.$api.search
        .updataRoleResources(this.userid, this.treekey)
        .then(res => {
          if (res.status == 200 && res.data.code == 0) {
            this.$message({
              message: '更新成功',
              type: 'success',
              duration: 500
            })

            this.dialogVisible3 = false
            this.loading.close()
          } else {
            this.$message({
              message: '修改失败,请重试',
              type: 'error',
              duration: 500
            })
            this.loading.close()
          }
        })
    },
    getRoleResources(row) {
      this.$api.search.getRoleResources(row.id).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          for (let el in this.ResourcesData) {
            for (let el2 in data) {
              if (this.ResourcesData[el] == data[el2].label) {
                this.ResourcesData[el] = data[el2]
              }
            }
          }

          let treekey = []

          for (let el3 in data) {
            if (data[el3].access == true) {
              // treekey.push(data[el3].id)
            }
            if (data[el3].childList.length > 0) {
              for (let el4 in data[el3].childList) {
                if (
                  data[el3].childList[el4].access == true &&
                  data[el3].childList[el4].childList.length == 0
                ) {
                  treekey.push(data[el3].childList[el4].id)
                }
                if (data[el3].childList[el4].childList.length > 0) {
                  for (let el5 in data[el3].childList[el4].childList) {
                    if (
                      data[el3].childList[el4].childList[el5].access == true &&
                      data[el3].childList[el4].childList[el5].childList
                        .length == 0
                    ) {
                      treekey.push(data[el3].childList[el4].childList[el5].id)
                    }
                  }
                }
              }
            }
          }

          this.treekey = treekey
          this.$nextTick(() => {
            this.$refs.tree_right.setCheckedKeys(treekey)
          })
          console.log(treekey)
          this.dialogVisible3 = true
          this.loading.close()
        } else {
          this.loading.close()
          this.$message({
            message: '请求数据失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    handleClick(row) {
      console.log(row)
      this.updataId = row.id
      this.form1.id = row.id
      this.form1.name = row.name
      this.dialogVisible = true
    },
    filterTag(value, row) {
      return row.valid === value
    },
    //全局loading
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
    }
  },
  mounted() {
    this.getRoleList()
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.form1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
  }
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
</style>

