<template>
  <!-- 订单查询 -->
  <div class="main">
    <div class="form">
      <el-form
        :inline="true"
        :model="updatapage"
        class="demo-form-inline"
        ref="updatapage"
        size="small"
      >
        <el-form-item label="订单编号">
          <el-input placeholder="输入订单号" v-model="updatapage.orderNo"></el-input>
        </el-form-item>
        <el-form-item label="来源渠道">
          <el-select placeholder="来源渠道" v-model="updatapage.channelId">
            <el-option :value="null" label="全部"></el-option>
            <el-option
              :key="item.id"
              :label="item.name"
              :value="item.id"
              v-for="(item) in SourcesData"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收件人姓名">
          <el-input placeholder="收件人姓名" v-model="updatapage.receiverName"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号">
          <el-input placeholder="收件人手机号" v-model="updatapage.receiverMobile"></el-input>
        </el-form-item>
        <!-- change-on-select -->
        <el-form-item label="地区">
          <el-cascader
            :options="options"
            @change="handleChange"
            class="wd400"
            expand-trigger="hover"
            v-model="workarea"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input placeholder="详细地址" style="width:30rem" v-model="updatapage.addressDetail"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            :picker-options="pickerOptions"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="time"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select placeholder="订单状态" v-model="updatapage.state">
            <el-option :value="null" label="全部"></el-option>
            <el-option label="待付款" value="UNPAID"></el-option>
            <el-option label="已付款" value="PAID"></el-option>
            <el-option label="生产中" value="HANDLING"></el-option>
            <el-option label="已发货" value="SENT"></el-option>
            <el-option label="已签收" value="RECEIVED"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="onSubmit"
            type="primary"
            v-if="$store.state.resourceCodeList.goods_order_list"
          >查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('updatapage')" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="button">
      <el-button
        @click="Submitin"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.goods_order_excel_import"
      >导入订单信息</el-button>
      <el-button @click="exportExcel" size="small" type="primary">导出订单(内部)</el-button>
      <el-button
        @click="Submitout"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.goods_order_export_vendor"
      >导出商品信息(给供应商)</el-button>
    </div>-->
    <div class="table">
      <el-table :data="tableData" border height="52rem" stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="订单号" prop="orderNo" width="150"></el-table-column>
        <el-table-column label="来源渠道" prop="channelName" width="120"></el-table-column>
        <el-table-column label="渠道订单号" prop="channelOrderNo" width="150"></el-table-column>
        <el-table-column label="关联资源品类" prop="channelCategoryName" width="120"></el-table-column>
        <el-table-column label="支付金额" prop="payment" width="70"></el-table-column>
        <el-table-column label="分成比例" prop="share" width="70"></el-table-column>
        <el-table-column label="收件人姓名" prop="receiver.name" width="120"></el-table-column>
        <el-table-column label="收件人手机号" prop="receiver.mobile" width="120"></el-table-column>
        <el-table-column label="省" prop="receiver.address.province" width="80"></el-table-column>
        <el-table-column label="市" prop="receiver.address.city" width="80"></el-table-column>
        <el-table-column label="区" prop="receiver.address.district" width="80"></el-table-column>
        <el-table-column label="详细地址" prop="receiver.address.detail" width="120"></el-table-column>
        <!-- <el-table-column label="用户id" prop="zip" width="120"></el-table-column> -->
        <el-table-column label="创建时间" prop="createTime" width="120"></el-table-column>
        <el-table-column label="付款时间" prop="payTime" width="120"></el-table-column>
        <el-table-column label="订单状态" prop="state" width="120"></el-table-column>
        <el-table-column label="短信状态" prop="smsState" width="120"></el-table-column>

        <el-table-column fixed="right" label="商品详情" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleDetail(scope.row)"
              size="small"
              type="text"
              v-if="$store.state.resourceCodeList.goods_order_get_goods"
            >商品详情</el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="物流" width="100">
          <template slot-scope="scope">
            <!-- <el-button
              @click="handleDelivery(scope.row)"
              size="small"
              type="text"
              v-if="$store.state.resourceCodeList.goods_order_sent"
            >发货</el-button>-->
            <el-button
              @click="handleExpress(scope.row)"
              size="small"
              type="text"
              v-if="$store.state.resourceCodeList.goods_order_get_express"
            >运单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage"
          :page-size="10"
          :page-sizes="[10, 20, 30, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog table">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="商品详情" width="70%">
        <el-table :data="tableData2" border height="250" stripe style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="商品编号" prop="id" width="50"></el-table-column>
          <el-table-column label="品类" prop="categoryName" width="130"></el-table-column>
          <el-table-column label="规格" prop="specName" width="80"></el-table-column>
          <el-table-column label="款式" prop="designName"></el-table-column>
          <el-table-column label="型号" prop="typeName"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible5"
        title="运单信息"
        width="70%"
      >
        <el-table :data="tableData3" border height="250" stripe style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="运单编号" prop="id" width="50"></el-table-column>
          <el-table-column label="快递公司" prop="expressCoName" width="80"></el-table-column>
          <el-table-column label="运单号" prop="expressNo" width="200"></el-table-column>
          <el-table-column label="收件人姓名" prop="receiver.name" width="120"></el-table-column>
          <el-table-column label="收件人手机号" prop="receiver.mobile" width="120"></el-table-column>
          <el-table-column label="省" prop="receiver.address.province" width="80"></el-table-column>
          <el-table-column label="市" prop="receiver.address.city" width="80"></el-table-column>
          <el-table-column label="区" prop="receiver.address.district" width="80"></el-table-column>
          <el-table-column label="详细地址" prop="receiver.address.detail" width="120"></el-table-column>
          <el-table-column label="创建时间" prop="zip" width="120"></el-table-column>
          <el-table-column label="发货时间" prop="sentTime" width="200"></el-table-column>
          <el-table-column label="订单状态" prop="state" width="120"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible2" title="批量导入">
        <el-upload
          :beforeUpload="beforeUpload"
          :file-list="filesUpload"
          :headers="headers"
          :on-change="removeLastFile"
          :on-error="Onerror"
          :on-success="Onsuccess"
          accept=".xls, .xlsx"
          action="/api/be/v1/excel/goodsOrder"
          class="upload-demo"
          drag
          name="excel"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip" style="color:red">
            只支持Excel文件的导入,格式包括:xls, xlsx
            <br />单个导入的文件大小不能超过4M
            <br />
            <button @click="templateDL">模板下载</button>
          </div>
        </el-upload>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible3"
        title="导出订单"
        width="30%"
      >
        <h2>即将导出{{total}}条订单</h2>
        <el-checkbox style="margin-top:2rem;font-size:2rem" v-model="checked">将商品所属订单订单状态标记为生产中</el-checkbox>
        <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button @click="goodsOut" type="primary">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible4"
        title="快递发货"
        width="50rem"
      >
        <div class="form1">
          <div>
            <el-form
              :model="hdelivery"
              label-position="left"
              label-width="10rem"
              ref="form"
              style="width:40rem"
            >
              <el-form-item label="快递公司" style="width:35rem">
                <el-select placeholder="快递公司" v-model="hdelivery.expressCoId">
                  <el-option
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    v-for="(item) in deliveryData"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="运单号" style="width:35rem">
                <el-input v-model="hdelivery.expressNo"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible4 = false">取 消</el-button>
            <el-button @click="Delivery" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { regionDataPlus, CodeToText } from 'element-china-area-data'
import FileSaver from 'file-saver'
import formatDate from '../../function/Date.js'

export default {
  data() {
    return {
      updatapage: {
        user: '',
        region: ''
      },
      updatapage: {
        // orderNo: '',
        // channelCategoryId: '',
        // receiverName: '',
        // receiverMobile: '',
        // addressDetail: '',
        // state: '',
        page: 1,
        count: 10
      },
      hdelivery: {
        expressCoId: '',
        expressNo: ''
      },
      hdelivery_id: '',
      dialogVisible4: false,
      deliveryData: [],
      tableData: [],
      SourcesData: [],
      headers: {},
      workarea: [],
      total: 0,
      currentPage: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      time: [],
      options: [],

      dialogVisible2: false,
      dialogVisible3: false,

      filesUpload: [],
      currentPage4: 1,
      checked: true,
      dialogVisible: false,
      tableData2: [],
      dialogVisible5: false,
      tableData3: [],
      status: {
        UNPAID: '待付款',
        PAID: '已付款',
        HANDLING: '生产中',
        SENT: '已发货',
        RECEIVED: '已签收'
      },
      smsState: {
        NONE: '未发送短信',
        HANDLE_ORDER: '已发送下单短信',
        SENT_ORDER: '已发送发货短信'
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.updatapage.addressProvince) {
        delete this.updatapage.addressProvince
      }
      if (this.updatapage.addressCity) {
        delete this.updatapage.addressCity
      }
      if (this.updatapage.addressDistrict) {
        delete this.updatapage.addressDistrict
      }

      if (this.workarea) {
        let ssq = this.workarea.map(v => {
          return CodeToText[v]
        })
        ssq.forEach(v => {
          if (v.includes('省')) {
            this.updatapage.addressProvince = v
          } else if (v.includes('市') && v !== '市辖区') {
            this.updatapage.addressCity = v
          } else if (v.includes('区') && v !== '市辖区') {
            this.updatapage.addressDistrict = v
          }
        })
      }

      this.getSalesList()
    },
    resetForm(formName) {
      this.time = []
      this.workarea = []
      this.updatapage = {
        page: 1,
        count: 10
      }
    },
    handleDelivery(e) {
      this.hdelivery_id = e.id
      this.dialogVisible4 = true
    },

    handleDetail(e) {
      console.log(e)
      this.getSalesListDetail(e.id)
      this.dialogVisible = true
    },
    handleExpress(e) {
      console.log(e)
      this.getSalesListExpress(e.id)
      this.dialogVisible5 = true
    },
    Submitin() {
      this.dialogVisible2 = true
    },
    Submitout() {
      this.dialogVisible3 = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleChange(value) {
      console.log(value)
    },
    Onsuccess(res) {
      this.$message({
        message: res.msg,
        type: 'success',
        duration: 1000
      })
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 4 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.updatapage.page = val
      this.getSalesList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage.count = val
      this.getSalesList()
    },

    //接口方法
    //订单列表
    getSalesList() {
      if (this.time) {
        if (this.time.length > 0) {
          this.updatapage.createTimeBegin = this.time[0] + ' 00:00:00'
          this.updatapage.createTimeEnd = this.time[1] + ' 23:59:59'
        }
      } else {
        delete this.updatapage.createTimeBegin
        delete this.updatapage.createTimeEnd
      }
      this.$api.search.getSalesList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total = data.total
          this.tableData = data.list

          this.tableData.forEach(e => {
            e.state = this.status[e.state]
          })
          this.tableData.forEach(e => {
            e.smsState = this.smsState[e.smsState]
          })
        }
      })
    },
    //来源渠道列表
    getSourcesList() {
      console.log('获取来源渠道列表')
      this.$api.search.getSourcesList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.SourcesData = data.list
          console.log(this.SourcesData)
        }
      })
    },
    //快递公司列表
    getCourierList() {
      console.log('获取快递公司列表')
      this.$api.search.getCourierList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.deliveryData = data.list
          console.log(this.deliveryData)
        }
      })
    },
    //订单商品详情接口
    getSalesListDetail(id) {
      console.log('订单商品详情接口')
      this.$api.search.getSalesListDetail(id).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.tableData2 = data
          console.log(this.tableData2)
        }
      })
    },
    //运单查询接口
    getSalesListExpress(id) {
      console.log('运单查询接口')
      this.$api.search.getSalesListExpress(id).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.tableData3 = data
          this.tableData3.forEach(e => {
            e.state = this.status[e.state]
          })
          console.log(this.tableData3)
        }
      })
    },
    //发货接口
    Delivery() {
      this.$api.search.delivery(this.hdelivery, this.hdelivery_id).then(res => {
        console.log(res)
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '发货成功',
            type: 'success',
            duration: 1000
          })
          this.dialogVisible4 = false
        } else {
          this.$message({
            message: '快递单号重复,请重试',
            type: 'error',
            duration: 1000
          })
          this.dialogVisible4 = false
        }
      })
    },
    //导入excel模板下载
    templateDL() {
      this.$api.search.getTemplate().then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        let url = URL.createObjectURL(blob)
        let newFileUrl = encodeURI(url + '?name=goodsOrderList.xlsx', 'utf-8')
        let downloadA = document.createElement('a')
        downloadA.download = '导入模板.xlsx'
        downloadA.href = window.URL.createObjectURL(blob)
        downloadA.click()
        window.URL.revokeObjectURL(downloadA.href)
      })
    },

    //导出商品接口
    goodsOut() {
      this.$api.search.goodsOut(this.updatapage).then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        let url = URL.createObjectURL(blob)
        let newFileUrl = encodeURI(url + '?name=goodsOrderList.xlsx', 'utf-8')
        let downloadA = document.createElement('a')
        downloadA.download = '导出订单.xlsx'
        downloadA.href = window.URL.createObjectURL(blob)
        downloadA.click()
        window.URL.revokeObjectURL(downloadA.href)
      })
      this.dialogVisible3 = false
    },
    //定义默认时间
    defaultDate() {
      let date = new Date()
      let date2 = new Date().setDate(new Date().getDate() - 7)
      let time1 = formatDate(date)
      let time2 = formatDate(date2)
      this.time.push(time2)
      this.time.push(time1)
    },

    //定义导出Excel表格事件
    exportExcel() {
      // /* 从表生成工作簿对象 */
      // let wb = XLSX.utils.table_to_book(document.querySelector(".el-table"));
      // /* 获取二进制字符串作为输出 */
      // let wbout = XLSX.write(wb, {
      //   bookType: "xlsx",
      //   bookSST: true,
      //   type: "array"
      // });
      // try {
      //   FileSaver.saveAs(
      //     //Blob 对象表示一个不可变、原始数据的类文件对象。
      //     //Blob 表示的不一定是JavaScript原生格式的数据。
      //     //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
      //     //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
      //     new Blob([wbout], { type: "application/octet-stream" }),
      //     //设置导出文件名称
      //     "导出订单表.xlsx"
      //   );
      // } catch (e) {
      //   if (typeof console !== "undefined") console.log(e, wbout);
      // }
      // return wbout;
    }
  },
  created() {},
  mounted() {
    this.options = regionDataPlus
    this.headers = { Authorization: this.$store.state.token }
    this.defaultDate()
    this.getSalesList()
    this.getSourcesList()
    this.getCourierList()
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.form1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
    /deep/ .el-input__inner {
      width: 25rem;
    }
  }
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
</style>
