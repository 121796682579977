<template>
  <!-- 货品 -->
  <div class="main">
    <div class="button">
      <el-button
        @click="Submitin"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.stock_add"
      >新增</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border stripe style="width: 100%">
        <!-- <el-table-column type="expand">
          <template slot-scope="props">
            <el-form class="demo-table-expand" inline label-position="left">
              <el-form-item label="可选规格">
                <span>{{ props.row.spec }}</span>
              </el-form-item>
              <el-form-item label="可选型号">
                <span>{{ props.row.type }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>-->
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="货品编号" prop="id"></el-table-column>
        <el-table-column label="货品名称" prop="name"></el-table-column>
        <el-table-column label="所属品类名称" prop="category.name"></el-table-column>
        <el-table-column label="款式名称" prop="design.name"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="可选规格" prop="spec"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="可选型号" prop="type"></el-table-column>
        <el-table-column
          :filter-method="filterTag"
          :filters="[{ text: '可用', value: '可用' }, { text: '作废', value: '作废' }]"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.valid === true ? 'success' : 'danger'"
              disable-transitions
            >{{scope.row.valid === true?"可用":"作废"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="167">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              size="small"
              type="primary"
              v-if="$store.state.resourceCodeList.stock_update"
            >修改</el-button>
            <el-button
              @click="updataStatuGoods(scope.row)"
              size="small"
              type="danger"
              v-if="scope.row.valid === true && $store.state.resourceCodeList.stock_valid "
            >作废</el-button>
            <el-button
              @click="updataStatuGoods(scope.row)"
              size="small"
              type="success"
              v-if="scope.row.valid === false && $store.state.resourceCodeList.stock_valid"
            >激活</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage4"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="修改货品">
        <div class="form1">
          <div>
            <el-form :model="form1" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="货品编号">
                <el-input readonly v-model="form1.id"></el-input>
              </el-form-item>
              <el-form-item label="货品名称">
                <el-input v-model="form1.name"></el-input>
              </el-form-item>
              <el-form-item label="品类名称">
                <el-popover placement="bottom" trigger="click" v-model="visible1" width="500">
                  <el-table
                    :data="tableData_C"
                    :row-class-name="tableRowClassName_C"
                    @row-dblclick="handledbClick_C"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column label="品类编号" property="id" width="100"></el-table-column>
                    <el-table-column label="品类名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_C"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_C"
                      @current-change="handleCurrentChange_C"
                      @size-change="handleSizeChange_C"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_C"
                    readonly
                    slot="reference"
                    v-model="form1.cname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="款式名称">
                <el-popover placement="bottom" trigger="click" v-model="visible2" width="500">
                  <el-table
                    :data="tableData_S"
                    :row-class-name="tableRowClassName_S"
                    @row-dblclick="handledbClick_S"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column label="款式编号" property="id" width="100"></el-table-column>
                    <el-table-column label="款式名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_S"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_S"
                      @current-change="handleCurrentChange_S"
                      @size-change="handleSizeChange_S"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_S"
                    readonly
                    slot="reference"
                    v-model="form1.sname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="规格名称">
                <el-popover placement="bottom" trigger="click">
                  <el-table
                    :data="tableData_Ma"
                    :row-key="getRowKey_Ma"
                    @selection-change="handleSelectionChange_Ma"
                    max-height="250"
                    ref="multipleTable_Ma"
                    style="user-select:none"
                  >
                    <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
                    <el-table-column label="规格编号" property="id" width="100"></el-table-column>
                    <el-table-column label="规格名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_Ma"
                      :page-size="100"
                      :page-sizes="[100, 200, 300, 400]"
                      :total="total_Ma"
                      @current-change="handleCurrentChange_Ma"
                      @size-change="handleSizeChange_Ma"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_Ma"
                    readonly
                    slot="reference"
                    v-model="form1.maname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="型号名称">
                <el-popover placement="bottom" trigger="click" width="500">
                  <el-table
                    :data="tableData_Mo"
                    :row-key="getRowKey_Mo"
                    @selection-change="handleSelectionChange_Mo"
                    max-height="250"
                    ref="multipleTable_Mo"
                    style="user-select:none"
                  >
                    <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
                    <el-table-column label="型号编号" property="id" width="100"></el-table-column>
                    <el-table-column label="型号名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_Mo"
                      :page-size="100"
                      :page-sizes="[100, 200, 300, 400]"
                      :total="total_Mo"
                      @current-change="handleCurrentChange_Mo"
                      @size-change="handleSizeChange_Mo"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_Mo"
                    readonly
                    slot="reference"
                    v-model="form1.moname"
                  ></el-input>
                </el-popover>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="updataGoods" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible2"
        title="新增货品"
        width="50rem"
      >
        <div class="form1">
          <div>
            <el-form :model="form" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="货品名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="品类名称">
                <el-popover placement="bottom" trigger="click" v-model="visible3" width="500">
                  <el-table
                    :data="tableData_C"
                    :row-class-name="tableRowClassName_C"
                    @row-dblclick="handledbClick_C"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column label="品类编号" property="id" width="100"></el-table-column>
                    <el-table-column label="品类名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_C"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_C"
                      @current-change="handleCurrentChange_C"
                      @size-change="handleSizeChange_C"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_C"
                    readonly
                    slot="reference"
                    v-model="form.cname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="款式名称">
                <el-popover placement="bottom" trigger="click" v-model="visible4" width="500">
                  <el-table
                    :data="tableData_S"
                    :row-class-name="tableRowClassName_S"
                    @row-dblclick="handledbClick_S"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column label="款式编号" property="id" width="100"></el-table-column>
                    <el-table-column label="款式名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_S"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_S"
                      @current-change="handleCurrentChange_S"
                      @size-change="handleSizeChange_S"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_S"
                    readonly
                    slot="reference"
                    v-model="form.sname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="规格名称">
                <el-popover placement="bottom" trigger="click" width="500">
                  <el-table
                    :data="tableData_Ma"
                    :row-key="getRowKey_Ma"
                    @selection-change="handleSelectionChange_Ma"
                    max-height="250"
                    ref="multipleTable_Ma"
                    style="user-select:none"
                  >
                    <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
                    <el-table-column label="规格编号" property="id" width="100"></el-table-column>
                    <el-table-column label="规格名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_Ma"
                      :page-size="100"
                      :page-sizes="[100, 200, 300, 400]"
                      :total="total_Ma"
                      @current-change="handleCurrentChange_Ma"
                      @size-change="handleSizeChange_Ma"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_Ma"
                    readonly
                    slot="reference"
                    v-model="form.maname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="型号名称">
                <el-popover placement="bottom" trigger="click" width="500">
                  <el-table
                    :data="tableData_Mo"
                    :row-key="getRowKey_Mo"
                    @selection-change="handleSelectionChange_Mo"
                    max-height="250"
                    ref="multipleTable_Mo"
                    style="user-select:none"
                  >
                    <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
                    <el-table-column label="型号编号" property="id" width="100"></el-table-column>
                    <el-table-column label="型号名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_Mo"
                      :page-size="100"
                      :page-sizes="[100, 200, 300, 400]"
                      :total="total_Mo"
                      @current-change="handleCurrentChange_Mo"
                      @size-change="handleSizeChange_Mo"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_Mo"
                    readonly
                    slot="reference"
                    v-model="form.moname"
                  ></el-input>
                </el-popover>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible2 = false">取 消</el-button>
            <el-button @click="addGoods" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible3" title="提示" width="30%">
        <h2>即将导出xx条订单的xx条商品信息</h2>
        <el-checkbox style="margin-top:2rem;font-size:2rem" v-model="checked">将商品所属订单订单状态标记为生产中</el-checkbox>
        <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button @click="dialogVisible3 = false" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { regionDataPlus } from 'element-china-area-data'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      formInline: {
        user: '',
        region: '',
        workarea: []
      },
      //--------分割--------
      //新增修改表单
      form: {
        name: '',
        cname: '',
        categoryId: '',
        sname: '',
        designId: '',
        maname: '',
        specIdList: [],
        moname: '',
        typeIdList: []
      },
      form1: {
        name: '',
        cname: '',
        categoryId: '',
        sname: '',
        designId: '',
        maname: '',
        specIdList: [],
        moname: '',
        typeIdList: []
      },

      updatapage: {
        page: 1,
        count: 10
      },
      updataId: '',
      total: 0,
      tableData: [],
      //关联表单
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      //品类
      total_C: 0,
      tableData_C: [],
      currentPage_C: 1,
      updatapage_C: {
        page: 1,
        count: 10,
        valid: true
      },
      popover_C: false,
      //款式
      total_S: 0,
      tableData_S: [],
      currentPage_S: 1,
      updatapage_S: {
        page: 1,
        count: 10,
        valid: true
      },
      //规格
      total_Ma: 0,
      tableData_Ma: [],
      currentPage_Ma: 1,
      updatapage_Ma: {
        page: 1,
        id: '',
        count: 100,
        valid: true
      },
      clear_Ma: false,
      stockList_Ma: '',
      //型号
      total_Mo: 0,
      tableData_Mo: [],
      currentPage_Mo: 1,
      updatapage_Mo: {
        page: 1,
        id: '',
        count: 100,
        valid: true
      },
      clear_Mo: false,
      stockList_Mo: '',
      //--------分割--------
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      options: regionDataPlus,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      filesUpload: [],
      currentPage4: 1,
      checked: true,
      tableData2: [],
      updateif: true
    }
  },
  watch: {
    'form.cname'(val) {
      ;[
        this.form.specIdList,
        this.form.maname,
        this.form.typeIdList,
        this.form.moname
      ] = ['', '', '', '']
    },
    'form1.cname'(newval, oldval) {
      if (oldval !== '') {
        if (this.updateif) {
          ;[
            this.form1.specIdList,
            this.form1.maname,
            this.form1.typeIdList,
            this.form1.moname
          ] = ['', '', '', '']
        }
      }
    },
    dialogVisible(val) {
      if (val == false) {
        this.form1.cname = ''
      }
    },
    tableData_Ma() {
      setTimeout(() => {
        this.select_Ma()
      })
    },
    tableData_Mo() {
      setTimeout(() => {
        this.select_Mo()
      })
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!')
    },

    //修改特殊处理
    handleClick(row) {
      console.log(row)
      this.updateif = false
      this.clear()
      this.updataId = row.id
      this.form1.id = row.id
      this.form1.name = row.name
      this.form1.cname = row.category.name
      this.form1.categoryId = row.category.id
      this.form1.sname = row.design.name
      this.form1.designId = row.design.id

      this.form1.maname = row.spec
      for (let el in row.specList) {
        this.form1.specIdList.push(row.specList[el].id)
      }
      this.stockList_Ma = row.specList
      this.form1.moname = row.type
      for (let el in row.typeList) {
        this.form1.typeIdList.push(row.typeList[el].id)
      }
      //迫不得已 慎用
      setTimeout(() => {
        this.updateif = true
      })
      this.stockList_Mo = row.typeList
      this.dialogVisible = true
    },
    //清空选择
    clear() {
      this.form = {
        name: '',
        cname: '',
        sname: '',
        categoryId: '',
        designId: '',
        maname: '',
        specIdList: [],
        moname: '',
        typeIdList: []
      }
      this.form1 = {
        name: '',
        cname: '',
        sname: '',
        categoryId: '',
        designId: '',
        maname: '',
        specIdList: [],
        moname: '',
        typeIdList: []
      }
      this.stockList_Ma = ''
      this.stockList_Mo = ''
    },
    Submitin() {
      this.clear()
      this.currentPage_Ma = 1
      this.updatapage_Ma = {
        page: 1,
        count: 100,
        valid: true
      }
      this.clear_Ma = true
      this.currentPage_Mo = 1
      this.updatapage_Mo = {
        page: 1,
        count: 100,
        valid: true
      }
      this.clear_Mo = true
      this.dialogVisible2 = true
    },

    Submitout() {
      this.dialogVisible3 = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleChange(value) {
      console.log(value)
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },

    //--------分割--------
    //接口方法定义

    addGoods() {
      console.log('新增货品')
      this.$api.search.addGoods(this.form).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '新增成功',
            type: 'success',
            duration: 500
          })
          this.getGoodsList()
          this.dialogVisible2 = false
        } else {
          this.$message({
            message: '新增失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    getGoodsList() {
      console.log('获取货品列表')
      this.$api.search.getGoodsList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total = data.total
          this.tableData = data.list
          for (let el in this.tableData) {
            this.tableData[el].type = ''
            this.tableData[el].spec = ''
            for (let el2 in this.tableData[el].typeList) {
              this.tableData[el].type =
                this.tableData[el].type +
                ' ' +
                this.tableData[el].typeList[el2].name
            }
            for (let el2 in this.tableData[el].specList) {
              this.tableData[el].spec =
                this.tableData[el].spec +
                ' ' +
                this.tableData[el].specList[el2].name
            }
          }
          console.log(this.tableData)
        }
      })
    },
    //翻页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.updatapage.page = val
      this.getGoodsList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage.count = val
      this.getGoodsList()
    },

    updataGoods() {
      console.log('更新货品')
      this.$api.search.updataGoods(this.updataId, this.form1).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 500
          })
          this.getGoodsList()
          this.dialogVisible = false
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    updataStatuGoods(row) {
      console.log('更新货品状态')
      let valid
      if (row.valid == true) {
        valid = false
      } else {
        valid = true
      }
      this.$api.search.updataStatuGoods(row.id, valid).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          row.valid = valid
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },

    //品类关联

    handleClick_C() {
      console.log('品类选择')
      this.getCategoryList_C()
    },
    getCategoryList_C() {
      this.$api.search.getCategoryList(this.updatapage_C).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total_C = data.total
          this.tableData_C = data.list
        }
      })
    },
    handledbClick_C(row) {
      console.log(row, '品类选中')
      this.form.cname = row.name
      this.form.categoryId = row.id
      this.form1.cname = row.name
      this.form1.categoryId = row.id
      this.visible1 = false
      this.visible3 = false
    },
    tableRowClassName_C({ row, rowIndex }) {
      if (row.name === this.form.cname || row.name === this.form1.cname) {
        return 'success-row'
      }
      return ''
    },
    handleCurrentChange_C(val) {
      console.log(`当前页: ${val}`)
      this.updatapage_C.page = val
      this.getCategoryList_C()
    },
    handleSizeChange_C(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage_C.count = val
      this.getCategoryList_C()
    },

    //款式关联
    handleClick_S() {
      console.log('款式选择')
      this.getStyleList_S()
    },
    getStyleList_S() {
      this.$api.search.getStyleList(this.updatapage_S).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total_S = data.total
          this.tableData_S = data.list
        }
      })
    },
    handledbClick_S(row) {
      console.log(row, '款式选中')
      this.form.sname = row.name
      this.form.designId = row.id
      this.form1.sname = row.name
      this.form1.designId = row.id
      this.visible2 = false
      this.visible4 = false
    },
    tableRowClassName_S({ row, rowIndex }) {
      if (row.name === this.form.sname || row.name === this.form1.sname) {
        return 'success-row'
      }
      return ''
    },
    handleCurrentChange_S(val) {
      console.log(`当前页: ${val}`)
      this.updatapage_S.page = val
      this.getStyleList_S()
    },
    handleSizeChange_S(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage_S.count = val
      this.getStyleList_S()
    },

    //品类下规格关联
    handleClick_Ma() {
      console.log('规格选择')
      this.MaterialList_get()
      if (this.clear_Ma) {
        this.$refs.multipleTable_Ma.clearSelection()
        this.clear_Ma = false
      }
    },

    MaterialList_get() {
      this.updatapage_Ma.id =
        this.form.categoryId !== ''
          ? this.form.categoryId
          : this.form1.categoryId
      if (this.updatapage_Ma.id === '') {
        this.total_Ma = 0
        this.tableData_Ma = []
        this.$message({
          message: '请先选择品类',
          type: 'error',
          duration: 2000
        })
      } else {
        this.$api.search
          .getCategoryMaterialList(this.updatapage_Ma.id, this.updatapage_Ma)
          .then(res => {
            if (res.status == 200 && res.data.code == 0) {
              let data = res.data.data
              this.total_Ma = data.total
              this.tableData_Ma = data.list
            }
          })
      }
    },
    //选中状态
    toggleSelection_Ma(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable_Ma.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable_Ma.clearSelection()
      }
    },
    select_Ma() {
      this.$refs.multipleTable_Ma.clearSelection()
      this.tableData_Ma.forEach(v => {
        for (let el in this.stockList_Ma) {
          if (v.id == this.stockList_Ma[el].id) {
            this.toggleSelection_Ma([v])
          }
        }
      })
    },

    handledbClick_Ma(row) {
      console.log(row, '规格选中')
      this.form.maname = row.name
      this.form.specIdList = row.id
      this.form1.maname = row.name
      this.form1.specIdList = row.id
    },

    handleCurrentChange_Ma(val) {
      console.log(`当前页: ${val}`)
      this.updatapage_Ma.page = val
      this.MaterialList_get()
    },
    handleSizeChange_Ma(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage_Ma.count = val
      this.MaterialList_get()
    },
    getRowKey_Ma(row) {
      return row.id
    },
    handleSelectionChange_Ma(val) {
      console.log('选中数据', val)
      let fmaname = '',
        fspecIdList = []
      for (let el in val) {
        fmaname += ' ' + val[el].name
        fspecIdList.push(val[el].id)
      }
      this.form.maname = fmaname
      this.form.specIdList = fspecIdList
      this.form1.maname = fmaname
      this.form1.specIdList = fspecIdList

      console.log('表单', this.form)
    },

    //品类下型号关联
    handleClick_Mo() {
      console.log('型号选择')
      this.ModelList_get()
      if (this.clear_Mo) {
        this.$refs.multipleTable_Mo.clearSelection()
        this.clear_Mo = false
      }
    },
    ModelList_get() {
      this.updatapage_Mo.id =
        this.form.categoryId !== ''
          ? this.form.categoryId
          : this.form1.categoryId

      if (this.updatapage_Mo.id === '') {
        this.total_Mo = 0
        this.tableData_Mo = []
        this.$message({
          message: '请先选择品类',
          type: 'error',
          duration: 2000
        })
      } else {
        this.$api.search
          .getCategoryModelList(this.updatapage_Mo.id, this.updatapage_Mo)
          .then(res => {
            if (res.status == 200 && res.data.code == 0) {
              let data = res.data.data
              this.total_Mo = data.total
              this.tableData_Mo = data.list
            }
          })
      }
    },
    //选中状态
    toggleSelection_Mo(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable_Mo.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable_Mo.clearSelection()
      }
    },
    select_Mo() {
      this.$refs.multipleTable_Mo.clearSelection()
      this.tableData_Mo.forEach(v => {
        for (let el in this.stockList_Mo) {
          if (v.id == this.stockList_Mo[el].id) {
            this.toggleSelection_Mo([v])
          }
        }
      })
    },
    handledbClick_Mo(row) {
      console.log(row, '型号选中')
      this.form.moname = row.name
      this.form.typeIdList = row.id
      this.form1.moname = row.name
      this.form1.typeIdList = row.id
    },

    handleCurrentChange_Mo(val) {
      console.log(`当前页: ${val}`)
      this.updatapage_Mo.page = val
      this.ModelList_get()
    },
    handleSizeChange_Mo(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage_Mo.count = val
      this.ModelList_get()
    },
    getRowKey_Mo(row) {
      return row.id
    },
    handleSelectionChange_Mo(val) {
      console.log('选中数据', val)
      this.form.moname = ''
      this.form.typeIdList = []
      for (let el in val) {
        this.form.moname += ' ' + val[el].name
        this.form.typeIdList.push(val[el].id)
      }
      this.form1.moname = ''
      this.form1.typeIdList = []
      for (let el in val) {
        this.form1.moname += ' ' + val[el].name
        this.form1.typeIdList.push(val[el].id)
      }
      console.log('表单', this.form)
    },
    //--------分割--------

    filterTag(value, row) {
      return row.valid === value
    }
  },
  mounted() {
    this.getGoodsList()
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.form1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
  }
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
  .demo-table-expand {
    // background-color: #99a9bf;
    font-size: 0;
    display: flex;
    flex-direction: column;
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 50%;
      /deep/ label {
        width: 90px;
        color: #99a9bf;
      }
    }
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

/deep/ .success-row {
  background: #d9f1cb;
}
</style>

