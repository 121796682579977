import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login/Login.vue";
import Layout from "../views/Layout/Layout.vue";

import ListAudit from "../components/salesList/ListAudit.vue";
import ListAudit_order from '../components/salesList/ListAudit_order.vue'
import ListAudit_sending from '../components/salesList/ListAudit_sending.vue'
import ListAudit_order_massage from '../components/salesList/ListAudit_order_massage.vue'
import ListAudit_sending_massage from '../components/salesList/ListAudit_sending_massage.vue'
import ListAudit_order_import from '../components/salesList/ListAudit_order_import.vue'
import ListAudit_sending_import from '../components/salesList/ListAudit_sending_import.vue'


import Purchase from "../components/Purchasehistory/Purchase.vue";
import Logistics from "../components/Logistics/Logistics.vue";
import Statistics from "../components/Statistics/Statistics.vue";
import Menu from "../components/Menu/Menu.vue";

import Sources from "../components/Basic/Sources.vue";
import Category from "../components/Basic/Category.vue";
import Material from "../components/Basic/Material.vue";
import Style from "../components/Basic/Style.vue";
import Model from "../components/Basic/Model.vue";
import Associated from "../components/Basic/Associated.vue";
import Courier from "../components/Basic/Courier.vue";
import Goods from "../components/Basic/Goods.vue";
import Activity from "../components/Basic/Activity.vue";

import Role from "../components/R.A/Role.vue";
import Account from "../components/R.A/Account.vue";

import Updatapass from '../components/updatapassword/updatapassword.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // redirect: '/Layout/ListAudit',
    name: "Login",
    component: Login
  },
  {
    path: "/Layout",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "ListAudit",
        name: "ListAudit",
        component: ListAudit
      },
      {
        path: "ListAudit_order",
        name: "ListAudit_order",
        component: ListAudit_order
      },
      {
        path: "ListAudit_sending",
        name: "ListAudit_sending",
        component: ListAudit_sending
      },
      {
        path: "ListAudit_order_massage",
        name: "ListAudit_order_massage",
        component: ListAudit_order_massage
      },
      {
        path: "ListAudit_sending_massage",
        name: "ListAudit_sending_massage",
        component: ListAudit_sending_massage
      },
      {
        path: "ListAudit_order_import",
        name: "ListAudit_order_import",
        component: ListAudit_order_import
      },
      {
        path: "ListAudit_sending_import",
        name: "ListAudit_sending_import",
        component: ListAudit_sending_import
      },
      {
        path: "Purchase",
        name: "Purchase",
        component: Purchase
      },
      {
        path: "Logistics",
        name: "Logistics",
        component: Logistics
      },
      {
        path: "Statistics",
        name: "Statistics",
        component: Statistics
      },
      {
        path: "Sources",
        name: "Sources",
        component: Sources
      },
      {
        path: "Category",
        name: "Category",
        component: Category
      },
      {
        path: "Material",
        name: "Material",
        component: Material
      },
      {
        path: "Style",
        name: "Style",
        component: Style
      },
      {
        path: "Model",
        name: "Model",
        component: Model
      },
      {
        path: "Associated",
        name: "Associated",
        component: Associated
      },
      {
        path: "Courier",
        name: "Courier",
        component: Courier
      },
      {
        path: "Goods",
        name: "Goods",
        component: Goods
      },
      {
        path: "Activity",
        name: "Activity",
        component: Activity
      },
      {
        path: "Role",
        name: "Role",
        component: Role
      },
      {
        path: "Account",
        name: "Account",
        component: Account
      },
      {
        path: "Menu",
        name: "Menu",
        component: Menu
      },
      {
        path: "Updatapass",
        name: "Updatapass",
        component: Updatapass
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
