<template>
  <div class="layout">
    <el-container style="height:100%; ">
      <el-header style="padding:0; background-color: #fff;">
        <div class="topheader">
          <div>
            <p>衍生品订单管理后台</p>
          </div>
          <div>
            <el-dropdown>
              <span class="el-dropdown-link">
                <i class="el-icon-user-solid"></i>
                {{ username }}({{roleName}})
                <i
                  class="el-icon-arrow-down el-icon--right"
                ></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="goUpdatapass">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="gologin">切换用户</el-dropdown-item>
                <el-dropdown-item @click.native="gologin">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container style="height:calc(100% - 6.3rem); margin-top:0.3rem;">
        <el-aside width="20rem">
          <div class="left">
            <el-menu
              :collapse="isCollapse"
              :collapse-transition="false"
              :default-active="$route.path"
              active-text-color="orange"
              class="el-menu-vertical-demo"
              router
            >
              <el-submenu index="/Layout?3" v-if="$store.state.resourceCodeList.goods_page">
                <template slot="title">
                  <i class="el-icon-s-order"></i>
                  <span slot="title">订单管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/Layout/ListAudit"
                    v-if="$store.state.resourceCodeList.goods_order_list"
                  >订单查询</el-menu-item>
                  <el-menu-item
                    index="/Layout/ListAudit_order_import"
                    v-if="$store.state.resourceCodeList.goods_order_excel_import"
                  >导入订单</el-menu-item>
                  <el-menu-item
                    index="/Layout/ListAudit_order"
                    v-if="$store.state.resourceCodeList.goods_order_batch_handle"
                  >下单给供应商</el-menu-item>
                  <el-menu-item
                    index="/Layout/ListAudit_sending_import"
                    v-if="$store.state.resourceCodeList.express_excel_import"
                  >导入物流信息</el-menu-item>
                  <el-menu-item
                    index="/Layout/ListAudit_sending"
                    v-if="$store.state.resourceCodeList.goods_order_sent"
                  >发货</el-menu-item>
                  <el-menu-item
                    index="/Layout/ListAudit_order_massage"
                    v-if="$store.state.resourceCodeList.goods_order_sms"
                  >发送下单短信</el-menu-item>
                  <el-menu-item
                    index="/Layout/ListAudit_sending_massage"
                    v-if="$store.state.resourceCodeList.goods_order_sms"
                  >发送发货短信</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <!-- <el-menu-item index="/Layout/Purchase">
                <i class="el-icon-shopping-bag-1"></i>
                <span slot="title">付款记录</span>
              </el-menu-item>-->
              <el-menu-item
                index="/Layout/Logistics"
                v-if="$store.state.resourceCodeList.express_page"
              >
                <i class="el-icon-truck"></i>
                <span slot="title">物流信息</span>
              </el-menu-item>
              <!-- <el-menu-item index="/Layout/Statistics">
                <i class="el-icon-s-data"></i>
                <span slot="title">数据统计</span>
              </el-menu-item>-->
              <el-submenu index="/Layout?1" v-if="$store.state.resourceCodeList.base_data_module">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span slot="title">基础数据</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/Layout/Sources"
                    v-if="$store.state.resourceCodeList.channel_page"
                  >来源渠道</el-menu-item>
                  <el-menu-item
                    index="/Layout/Category"
                    v-if="$store.state.resourceCodeList.category_page"
                  >品类</el-menu-item>
                  <el-menu-item
                    index="/Layout/Material"
                    v-if="$store.state.resourceCodeList.spec_page"
                  >规格/材质</el-menu-item>
                  <el-menu-item
                    index="/Layout/Style"
                    v-if="$store.state.resourceCodeList.design_page"
                  >款式</el-menu-item>
                  <el-menu-item
                    index="/Layout/Model"
                    v-if="$store.state.resourceCodeList.type_page"
                  >型号</el-menu-item>
                  <el-menu-item
                    index="/Layout/Associated"
                    v-if="$store.state.resourceCodeList.channel_category_page"
                  >关联资源品类</el-menu-item>
                  <el-menu-item
                    index="/Layout/Courier"
                    v-if="$store.state.resourceCodeList.express_co_page"
                  >快递公司</el-menu-item>
                  <el-menu-item
                    index="/Layout/Goods"
                    v-if="$store.state.resourceCodeList.stock_page"
                  >货品</el-menu-item>
                  <el-menu-item
                    index="/Layout/Activity"
                    v-if="$store.state.resourceCodeList.buy_activity_page"
                  >活动</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu
                index="/Layout?2"
                v-if="$store.state.resourceCodeList.account_role_module"
              >
                <template slot="title">
                  <i class="el-icon-s-custom"></i>
                  <span slot="title">账号角色权限</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/Layout/Role"
                    v-if="$store.state.resourceCodeList.role_page"
                  >角色&权限</el-menu-item>
                  <el-menu-item
                    index="/Layout/Account"
                    v-if="$store.state.resourceCodeList.admin_page"
                  >账号管理</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <!-- <el-menu-item index="/Layout/Menu">
                <i class="el-icon-set-up"></i>
                <span slot="title">菜单维护</span>
              </el-menu-item>-->
            </el-menu>
          </div>
        </el-aside>
        <el-main style="padding:0;">
          <div class="right">
            <div class="right-top">
              <span @click="menuchange">
                <img alt src="../../assets/zhankai.png" />
              </span>
              <p>{{ nav }}</p>
            </div>
            <div class="right-main">
              <router-view></router-view>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import ListAudit from '../../components/salesList/ListAudit.vue'
import data from '../../assets/data.json'

export default {
  data() {
    return {
      username: null,
      isCollapse: false,
      roleName: null,
      nav: '订单管理'
    }
  },
  watch: {
    '$route.path': function() {
      let a = this.$route.path.lastIndexOf('/')
      let b = this.$route.path.slice(a + 1, this.$route.path.length)
      this.nav = data[b]
    }
  },
  methods: {
    menuchange() {
      this.isCollapse = !this.isCollapse
    },
    gologin() {
      this.$store.commit('token', '')
      this.$store.commit('username', '')
      this.$store.commit('roleName', '')
      this.$store.commit('resourceCodeList', '')
      this.$router.push({
        path: '/',
        name: 'Login'
      })
    },
    goUpdatapass() {
      this.$router.push({
        path: '/Layout/Updatapass',
        name: 'Updatapass'
      })
    }
  },
  components: {
    ListAudit
  },
  created() {},
  mounted() {
    this.username = this.$store.state.username
    this.roleName = this.$store.state.roleName
  },
  updated() {}
}
</script>
<style lang="scss" scoped>
.layout {
  height: 100vh;
}

.topheader {
  width: 100%;
  height: 6rem;
  padding: 0 2rem;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  p {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 6rem;
  }
  div .el-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.el-aside {
  width: auto !important;
}

.left {
  width: auto;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(230, 224, 224, 0.5);

  /deep/ .el-menu {
    border: none;
  }
  /deep/ .el-menu-item {
    text-align: left;
  }

  /deep/ .el-submenu__title {
    text-align: left;
  }

  /deep/ .el-menu--inline .el-menu-item {
    text-align: center;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
  }
}

.right {
  width: 100%;
  height: 100%;
  .right-top {
    height: 2.7rem;
    display: flex;
    justify-content: flex-start;
    span {
      margin: 0.4rem 1rem;
      width: 1.9rem;
      img {
        object-fit: contain;
        width: 1.9rem;
        height: 1.9rem;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 2.7rem;
    }
  }

  .right-main {
    margin: 2rem;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
</style>
