<template>
  <!-- 下单给供应商 -->
  <div class="main">
    <div class="form">
      <el-form
        :inline="true"
        :model="updatapage"
        class="demo-form-inline"
        ref="updatapage"
        size="small"
      >
        <el-form-item label="订单编号">
          <el-input placeholder="输入订单号" v-model="updatapage.orderNo"></el-input>
        </el-form-item>
        <el-form-item label="收件人姓名">
          <el-input placeholder="收件人姓名" v-model="updatapage.receiverName"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号">
          <el-input placeholder="收件人手机号" v-model="updatapage.receiverMobile"></el-input>
        </el-form-item>
        <!-- change-on-select -->
        <el-form-item label="地区">
          <el-cascader
            :options="options"
            @change="handleChange"
            class="wd400"
            expand-trigger="hover"
            v-model="workarea"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input placeholder="详细地址" style="width:30rem" v-model="updatapage.addressDetail"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            :picker-options="pickerOptions"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="time"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="onSubmit"
            type="primary"
            v-if="$store.state.resourceCodeList.goods_order_list"
          >查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('updatapage')" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="button">
      <el-button
        @click="Submitin"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.goods_order_export_vendor"
      >下单</el-button>
      <el-button
        @click="Submitout"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.goods_order_export_vendor"
      >导出商品信息(给供应商)</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        border
        height="52rem"
        stripe
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="订单号" prop="orderNo" width="300"></el-table-column>
        <el-table-column label="收件人姓名" prop="receiver.name" width="120"></el-table-column>
        <el-table-column label="收件人手机号" prop="receiver.mobile" width="120"></el-table-column>
        <el-table-column label="省" prop="receiver.address.province" width="80"></el-table-column>
        <el-table-column label="市" prop="receiver.address.city" width="80"></el-table-column>
        <el-table-column label="区" prop="receiver.address.district" width="80"></el-table-column>
        <el-table-column label="详细地址" prop="receiver.address.detail"></el-table-column>
        <!-- <el-table-column label="用户id" prop="zip" width="120"></el-table-column> -->
        <el-table-column label="创建时间" prop="createTime" width="200"></el-table-column>
        <el-table-column label="付款时间" prop="payTime" width="200"></el-table-column>
        <el-table-column fixed="right" label="商品详情" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleDetail(scope.row)"
              size="small"
              type="text"
              v-if="$store.state.resourceCodeList.goods_order_get_goods"
            >商品详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage"
          :page-size="10"
          :page-sizes="[10, 20, 30, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog table">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="商品详情" width="70%">
        <el-table :data="tableData2" border height="250" stripe style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="商品编号" prop="id" width="50"></el-table-column>
          <el-table-column label="品类" prop="categoryName" width="130"></el-table-column>
          <el-table-column label="规格" prop="specName" width="80"></el-table-column>
          <el-table-column label="款式" prop="designName"></el-table-column>
          <el-table-column label="型号" prop="typeName"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible3"
        title="下单记录"
        width="70%"
      >
        <el-table :data="tableData4" border height="450" stripe style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="批次号" prop="id"></el-table-column>
          <el-table-column label="下单时间" prop="handleTime"></el-table-column>
          <el-table-column label="操作人" prop="username"></el-table-column>
          <el-table-column label="供应商表格" width="150">
            <template slot-scope="scope">
              <el-button @click="orderRecord(scope.row)" size="small" type="text">表格下载</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            :current-page="currentPage2"
            :page-size="10"
            :page-sizes="[10, 20, 30, 100]"
            :total="total2"
            @current-change="handleCurrentChange2"
            @size-change="handleSizeChange2"
            layout="total, sizes, prev, pager, next, jumper"
          ></el-pagination>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { regionDataPlus, CodeToText } from 'element-china-area-data'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import formatDate from '../../function/Date.js'

export default {
  data() {
    return {
      updatapage: {
        user: '',
        region: ''
      },
      updatapage: {
        // orderNo: '',
        // channelCategoryId: '',
        // receiverName: '',
        // receiverMobile: '',
        // addressDetail: '',
        state: 'PAID',
        page: 1,
        count: 10
      },
      updatapage2: {
        page: 1,
        count: 10
      },
      hdelivery: {
        expressCoId: '',
        expressNo: ''
      },
      hdelivery_id: '',
      dialogVisible4: false,
      deliveryData: [],
      goodsOrderIdList: [], //多选表格
      tableData: [],
      SourcesData: [],
      headers: {},
      workarea: [],
      total: 0,
      currentPage: 1,
      total2: 0,
      currentPage2: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      time: [],
      options: [],

      dialogVisible2: false,

      filesUpload: [],
      currentPage4: 1,
      checked: true,
      dialogVisible: false,
      tableData2: [],
      dialogVisible5: false,
      tableData3: [],
      dialogVisible3: false,
      tableData4: [],
      status: {
        UNPAID: '待付款',
        PAID: '已付款',
        HANDLING: '生产中',
        SENT: '已发货',
        RECEIVED: '已签收'
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.updatapage.addressProvince) {
        delete this.updatapage.addressProvince
      }
      if (this.updatapage.addressCity) {
        delete this.updatapage.addressCity
      }
      if (this.updatapage.addressDistrict) {
        delete this.updatapage.addressDistrict
      }

      if (this.workarea) {
        let ssq = this.workarea.map(v => {
          return CodeToText[v]
        })
        ssq.forEach(v => {
          if (v.includes('省')) {
            this.updatapage.addressProvince = v
          } else if (v.includes('市') && v !== '市辖区') {
            this.updatapage.addressCity = v
          } else if (v.includes('区') && v !== '市辖区') {
            this.updatapage.addressDistrict = v
          }
        })
      }

      this.getSalesList()
    },
    resetForm(formName) {
      this.time = []
      this.workarea = []
      this.updatapage = {
        state: 'PAID',
        page: 1,
        count: 10
      }
    },
    handleDelivery(e) {
      this.hdelivery_id = e.id
      this.dialogVisible4 = true
    },

    handleDetail(e) {
      console.log(e)
      this.getSalesListDetail(e.id)
      this.dialogVisible = true
    },
    handleExpress(e) {
      console.log(e)
      this.getSalesListExpress(e.id)
      this.dialogVisible5 = true
    },
    Submitin() {
      this.openFullScreen()
      this.goodsOrder()
    },
    Submitout() {
      this.orderBatches()
      this.dialogVisible3 = true
    },
    //表格多选
    handleSelectionChange(val) {
      this.goodsOrderIdList = []
      for (let el in val) {
        this.goodsOrderIdList.push(val[el].id)
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleChange(value) {
      console.log(value)
    },
    Onsuccess(res) {
      this.$message({
        message: res.msg,
        type: 'success',
        duration: 1000
      })
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 4 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.updatapage.page = val
      this.getSalesList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage.count = val
      this.getSalesList()
    },
    handleCurrentChange2(val) {
      console.log(`当前页: ${val}`)
      this.updatapage.page = val
      this.getSalesList()
    },
    handleSizeChange2(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage.count = val
      this.getSalesList()
    },

    //接口方法
    //订单列表
    getSalesList() {
      if (this.time) {
        if (this.time.length > 0) {
          this.updatapage.createTimeBegin = this.time[0] + ' 00:00:00'
          this.updatapage.createTimeEnd = this.time[1] + ' 23:59:59'
        }
      } else {
        delete this.updatapage.createTimeBegin
        delete this.updatapage.createTimeEnd
      }
      this.$api.search.getSalesList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total = data.total
          this.tableData = data.list

          this.tableData.forEach(e => {
            e.state = this.status[e.state]
          })
        }
      })
    },
    //来源渠道列表
    getSourcesList() {
      console.log('获取来源渠道列表')
      this.$api.search.getSourcesList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.SourcesData = data.list
          console.log(this.SourcesData)
        }
      })
    },
    //快递公司列表
    getCourierList() {
      console.log('获取快递公司列表')
      this.$api.search.getCourierList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.deliveryData = data.list
          console.log(this.deliveryData)
        }
      })
    },
    //订单商品详情接口
    getSalesListDetail(id) {
      console.log('订单商品详情接口')
      this.$api.search.getSalesListDetail(id).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.tableData2 = data
          console.log(this.tableData2)
        }
      })
    },
    //运单查询接口
    getSalesListExpress(id) {
      console.log('订单商品详情接口')
      this.$api.search.getSalesListExpress(id).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.tableData3 = data
          this.tableData3.forEach(e => {
            e.state = this.status[e.state]
          })
          console.log(this.tableData3)
        }
      })
    },
    //发货接口
    Delivery() {
      this.$api.search.delivery(this.hdelivery, this.hdelivery_id).then(res => {
        console.log(res)
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '发货成功',
            type: 'success',
            duration: 1000
          })
          this.dialogVisible4 = false
        } else {
          this.$message({
            message: '快递单号重复,请重试',
            type: 'error',
            duration: 1000
          })
          this.dialogVisible4 = false
        }
      })
    },
    //导入excel模板下载
    templateDL() {
      this.$api.search.getTemplate().then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        let url = URL.createObjectURL(blob)
        let newFileUrl = encodeURI(url + '?name=goodsOrderList.xlsx', 'utf-8')
        let downloadA = document.createElement('a')
        downloadA.download = '导入模板.xlsx'
        downloadA.href = window.URL.createObjectURL(blob)
        downloadA.click()
        window.URL.revokeObjectURL(downloadA.href)
      })
    },
    //下单接口
    goodsOrder() {
      let params = {}
      params['goodsOrderIdList'] = this.goodsOrderIdList
      this.$api.search.goodsOrder(this.goodsOrderIdList).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '下单成功',
            type: 'success',
            duration: 1000
          })
          this.getSalesList()
          this.loading.close()
        } else {
          this.$message({
            message: '下单失败',
            type: 'error',
            duration: 1000
          })
          this.getSalesList()
          this.loading.close()
        }
      })
    },
    //下单批次列表
    orderBatches() {
      this.$api.search.orderBatches(this.updatapage2).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total2 = data.total
          this.tableData4 = data.list
        }
      })
    },
    //导出供应商订单记录接口
    orderRecord(row) {
      let id = row.id
      this.$api.search.orderRecord(id).then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        let url = URL.createObjectURL(blob)
        let newFileUrl = encodeURI(url + '?name=goodsOrderList.xlsx', 'utf-8')
        let downloadA = document.createElement('a')
        downloadA.download = '供应商表格.xlsx'
        downloadA.href = window.URL.createObjectURL(blob)
        downloadA.click()
        window.URL.revokeObjectURL(downloadA.href)
      })
    },
    //导出商品接口
    goodsOut() {
      this.$api.search.goodsOut(this.updatapage).then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        let url = URL.createObjectURL(blob)
        let newFileUrl = encodeURI(url + '?name=goodsOrderList.xlsx', 'utf-8')
        let downloadA = document.createElement('a')
        downloadA.download = '导出订单.xlsx'
        downloadA.href = window.URL.createObjectURL(blob)
        downloadA.click()
        window.URL.revokeObjectURL(downloadA.href)
      })
      this.dialogVisible3 = false
    },
    //全局loading
    openFullScreen() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
    },
    //定义默认时间
    defaultDate() {
      let date = new Date()
      let date2 = new Date().setDate(new Date().getDate() - 3)
      let time1 = formatDate(date)
      let time2 = formatDate(date2)
      this.time.push(time2)
      this.time.push(time1)
    }
  },
  created() {},
  mounted() {
    this.options = regionDataPlus
    this.headers = { Authorization: this.$store.state.token }
    this.defaultDate()
    this.getSalesList()
    this.getSourcesList()
    this.getCourierList()
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.form1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
    /deep/ .el-input__inner {
      width: 25rem;
    }
  }
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
</style>
