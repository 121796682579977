<template>
  <!-- 活动 -->
  <div class="main">
    <div class="button">
      <el-button
        @click="Submitin"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.buy_activity_add"
      >新增</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="活动编号" prop="id"></el-table-column>
        <el-table-column label="活动名称" prop="name"></el-table-column>
        <el-table-column label="关联资源品类" prop="channelCategory.name" width="130"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="货品" prop="stock"></el-table-column>
        <el-table-column
          :filter-method="filterTag"
          :filters="[{ text: '可用', value: '可用' }, { text: '作废', value: '作废' }]"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.valid === true ? 'success' : 'danger'"
              disable-transitions
            >{{scope.row.valid === true?"可用":"作废"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="167">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              size="small"
              type="primary"
              v-if="$store.state.resourceCodeList.buy_activity_update"
            >修改</el-button>
            <el-button
              @click="updataStatuActivity(scope.row)"
              size="small"
              type="danger"
              v-if="scope.row.valid === true && $store.state.resourceCodeList.buy_activity_valid"
            >作废</el-button>
            <el-button
              @click="updataStatuActivity(scope.row)"
              size="small"
              type="success"
              v-if="scope.row.valid === false && $store.state.resourceCodeList.buy_activity_valid"
            >激活</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage4"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="修改活动">
        <div class="form1">
          <div>
            <el-form :model="form1" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="活动编号">
                <el-input readonly v-model="form1.id"></el-input>
              </el-form-item>
              <el-form-item label="活动名称">
                <el-input v-model="form1.name"></el-input>
              </el-form-item>
              <el-form-item label="关联资源品类名称">
                <el-popover placement="bottom" trigger="click" v-model="visible1" width="500">
                  <el-table
                    :data="tableData_As"
                    :row-class-name="tableRowClassName_As"
                    @row-dblclick="handledbClick_As"
                    max-height="250"
                    ref="multipleTable_As"
                    style="user-select:none"
                  >
                    <el-table-column label="关联资源品类编号" property="id" width="100"></el-table-column>
                    <el-table-column label="关联资源品类名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_As"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_As"
                      @current-change="handleCurrentChange_As"
                      @size-change="handleSizeChange_As"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_As"
                    readonly
                    slot="reference"
                    v-model="form1.asname"
                  ></el-input>
                </el-popover>
              </el-form-item>

              <el-form-item label="货品名称">
                <el-popover placement="bottom" trigger="click" width="500">
                  <el-table
                    :data="tableData_G"
                    @selection-change="handleSelectionChange_G"
                    class="test"
                    max-height="250"
                    ref="multipleTable"
                    style="user-select:none"
                  >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="货品编号" property="id" width="100"></el-table-column>
                    <el-table-column label="货品名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_G"
                      :page-size="100"
                      :page-sizes="[100, 200, 300, 400]"
                      :total="total_G"
                      @current-change="handleCurrentChange_G"
                      @size-change="handleSizeChange_G"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_G"
                    readonly
                    slot="reference"
                    v-model="form1.gname"
                  ></el-input>
                </el-popover>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="updataActivity" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible2"
        title="新增活动"
        width="50rem"
      >
        <div class="form1">
          <div>
            <el-form :model="form" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="活动名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="关联资源品类名称">
                <el-popover placement="bottom" trigger="click" v-model="visible2" width="500">
                  <el-table
                    :data="tableData_As"
                    :row-class-name="tableRowClassName_As"
                    @row-dblclick="handledbClick_As"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column label="关联资源品类编号" property="id" width="100"></el-table-column>
                    <el-table-column label="关联资源品类名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_As"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_As"
                      @current-change="handleCurrentChange_As"
                      @size-change="handleSizeChange_As"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleClick_As"
                    readonly
                    slot="reference"
                    v-model="form.asname"
                  ></el-input>
                </el-popover>
              </el-form-item>

              <el-form-item label="货品名称">
                <el-popover placement="bottom" trigger="click" width="500">
                  <el-table
                    :data="tableData_G"
                    @selection-change="handleSelectionChange_G"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="货品编号" property="id" width="100"></el-table-column>
                    <el-table-column label="货品名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_G"
                      :page-size="100"
                      :page-sizes="[100, 200, 300, 400]"
                      :total="total_G"
                      @current-change="handleCurrentChange_G"
                      @size-change="handleSizeChange_G"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>

                  <el-input
                    @click.native="handleClick_G"
                    readonly
                    slot="reference"
                    v-model="form.gname"
                  ></el-input>
                </el-popover>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible2 = false">取 消</el-button>
            <el-button @click="addActivity" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible3" title="提示" width="30%">
        <h2>即将导出xx条订单的xx条商品信息</h2>
        <el-checkbox style="margin-top:2rem;font-size:2rem" v-model="checked">将商品所属订单订单状态标记为生产中</el-checkbox>
        <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button @click="dialogVisible3 = false" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { regionDataPlus } from 'element-china-area-data'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      //--------分割--------
      //新增修改表单
      form: {
        name: '',
        asname: '',
        channelCategoryId: '',
        gname: '',
        stockIdList: []
      },
      form1: {
        name: '',
        asname: '',
        channelCategoryId: '',
        gname: '',
        stockIdList: []
      },

      updatapage: {
        page: 1,
        count: 10
      },
      updataId: '',
      total: 0,
      tableData: [],
      //关联表单
      visible1: false,
      visible2: false,
      //关联表单
      //品类
      total_As: 0,
      tableData_As: [],
      currentPage_As: 1,
      updatapage_As: {
        page: 1,
        count: 10,
        valid: true
      },
      //型号
      total_G: 0,
      tableData_G: [],
      currentPage_G: 1,
      updatapage_G: {
        page: 1,
        count: 100,
        valid: true
      },
      //--------分割--------
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      options: regionDataPlus,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      filesUpload: [],
      currentPage4: 1,
      checked: true,
      tableData2: [],
      stockList: ''
    }
  },
  watch: {
    tableData_G() {
      setTimeout(() => {
        this.select()
      })
    }
  },
  updated() {},

  methods: {
    onSubmit() {
      console.log('submit!')
    },

    //修改特殊处理
    handleClick(row) {
      console.log(row)
      this.clear()
      this.updataId = row.id
      this.form1.id = row.id
      this.form1.name = row.name
      this.form1.asname = row.channelCategory.name
      this.form1.channelCategoryId = row.channelCategory.id
      this.form1.gname = row.stock
      this.stockList = row.stockList
      for (let el in row.stockList) {
        this.form1.stockIdList.push(row.stockList[el].id)
      }
      this.dialogVisible = true
    },

    //清空表单
    clear() {
      this.form = {
        name: '',
        asname: '',
        channelCategoryId: '',
        gname: '',
        stockIdList: []
      }
      this.form1 = {
        name: '',
        asname: '',
        channelCategoryId: '',
        gname: '',
        stockIdList: []
      }
      this.stockList = ''
    },
    Submitin() {
      this.clear()
      this.dialogVisible2 = true
    },

    Submitout() {
      this.dialogVisible3 = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleChange(value) {
      console.log(value)
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },

    //--------分割--------
    //接口方法定义

    addActivity() {
      console.log('新增活动')
      this.$api.search.addActivity(this.form).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '新增成功',
            type: 'success',
            duration: 500
          })
          this.getActivityList()
          this.dialogVisible2 = false
        } else {
          this.$message({
            message: '新增失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    getActivityList() {
      console.log('获取活动列表')
      this.$api.search.getActivityList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total = data.total
          this.tableData = data.list
          for (let el in this.tableData) {
            this.tableData[el].stock = ''
            for (let el2 in this.tableData[el].stockList) {
              this.tableData[el].stock =
                this.tableData[el].stock +
                ' ' +
                this.tableData[el].stockList[el2].name
            }
          }
          console.log(this.tableData)
        }
      })
    },
    //翻页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.updatapage.page = val
      this.getActivityList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage.count = val
      this.getActivityList()
    },

    updataActivity() {
      console.log('更新活动')
      this.$api.search.updataActivity(this.updataId, this.form1).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 500
          })
          this.getActivityList()
          this.dialogVisible = false
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    updataStatuActivity(row) {
      console.log('更新活动状态')
      let valid
      if (row.valid == true) {
        valid = false
      } else {
        valid = true
      }
      this.$api.search.updataStatuActivity(row.id, valid).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          row.valid = valid
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },

    //关联资源品类关联
    handleClick_As() {
      console.log('关联资源品类选择')
      this.$api.search.getAssociatedList(this.updatapage_As).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total_As = data.total
          this.tableData_As = data.list
        }
      })
    },
    handledbClick_As(row) {
      console.log(row, '关联资源品类选中')
      this.form.asname = row.name
      this.form.channelCategoryId = row.id
      this.form1.asname = row.name
      this.form1.channelCategoryId = row.id
      this.visible1 = false
      this.visible2 = false
    },
    tableRowClassName_As({ row, rowIndex }) {
      if (row.name === this.form.asname || row.name === this.form1.asname) {
        return 'success-row'
      }
      return ''
    },
    handleCurrentChange_As(val) {
      console.log(`当前页: ${val}`)
      this.updatapage_As.page = val
      this.handleClick_As()
    },
    handleSizeChange_As(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage_As.count = val
      this.handleClick_As()
    },

    //货品关联
    handleClick_G() {
      this.$api.search.getGoodsList(this.updatapage_G).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total_G = data.total
          this.tableData_G = data.list
        }
      })
    },
    //选中状态
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    select() {
      this.$refs.multipleTable.clearSelection()
      this.tableData_G.forEach(v => {
        for (let el in this.stockList) {
          if (v.id == this.stockList[el].id) {
            this.toggleSelection([v])
          }
        }
      })
    },
    handledbClick_G(row) {
      console.log(row, '货品选中')
      this.form.gname = row.name
      this.form.stockIdList = row.id
      this.form1.gname = row.name
      this.form1.stockIdList = row.id
    },
    handleCurrentChange_G(val) {
      console.log(`当前页: ${val}`)
      this.updatapage_G.page = val
      this.handleClick_G()
    },
    handleSizeChange_G(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage_G.count = val
      this.handleClick_G()
    },
    handleSelectionChange_G(val) {
      console.log('选中数据', val)
      this.form.gname = ''
      this.form.stockIdList = []
      for (let el in val) {
        this.form.gname += ' ' + val[el].name
        this.form.stockIdList.push(val[el].id)
      }
      this.form1.gname = ''
      this.form1.stockIdList = []
      for (let el in val) {
        this.form1.gname += ' ' + val[el].name
        this.form1.stockIdList.push(val[el].id)
      }
      console.log('表单', this.form)
    },
    //--------分割--------

    filterTag(value, row) {
      return row.valid === value
    }
  },
  mounted() {
    this.getActivityList()
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.form1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
  }
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

/deep/ .success-row {
  background: #d9f1cb;
}
</style>

