<template>
  <!-- 关联资源品类 -->
  <div class="main">
    <div class="button">
      <el-button
        @click="Submitin"
        size="small"
        type="primary"
        v-if="$store.state.resourceCodeList.channel_category_add"
      >新增</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="关联资源品类编号" prop="id"></el-table-column>
        <el-table-column label="关联资源品类名称" prop="name"></el-table-column>
        <el-table-column label="资源所属渠道" prop="channel.name"></el-table-column>
        <el-table-column label="分成比例" prop="share"></el-table-column>
        <el-table-column
          :filter-method="filterTag"
          :filters="[{ text: '可用', value: '可用' }, { text: '作废', value: '作废' }]"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.valid === true ? 'success' : 'danger'"
              disable-transitions
            >{{scope.row.valid === true?"可用":"作废"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="167">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              size="small"
              type="primary"
              v-if="$store.state.resourceCodeList.channel_category_update"
            >修改</el-button>
            <el-button
              @click="updataStatuAssociated(scope.row)"
              size="small"
              type="danger"
              v-if="scope.row.valid === true && $store.state.resourceCodeList.channel_category_valid"
            >作废</el-button>
            <el-button
              @click="updataStatuAssociated(scope.row)"
              size="small"
              type="success"
              v-if="scope.row.valid === false && $store.state.resourceCodeList.channel_category_valid"
            >激活</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage4"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="修改关联资源品类">
        <div class="form1">
          <div>
            <el-form :model="form1" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="关联资源品类编号">
                <el-input readonly v-model="form1.id"></el-input>
              </el-form-item>
              <el-form-item label="关联资源品类名称">
                <el-input v-model="form1.name"></el-input>
              </el-form-item>
              <el-form-item label="资源所属渠道">
                <el-popover placement="bottom" trigger="click" v-model="visible1" width="500">
                  <el-table
                    :data="tableData_So"
                    :row-class-name="tableRowClassName"
                    @row-dblclick="handledbClick_So"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column label="资源所属渠道编号" property="id" width="100"></el-table-column>
                    <el-table-column label="资源所属渠道名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_So"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_So"
                      @current-change="handleCurrentChange_So"
                      @size-change="handleSizeChange_So"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleclick_So"
                    readonly
                    slot="reference"
                    v-model="form1.soname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="分成比例">
                <el-input v-model="form1.share"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="updataAssociated" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible2"
        title="新增关联资源品类"
        width="50rem"
      >
        <div class="form1">
          <div>
            <el-form :model="form" label-width="auto" ref="form" style="width:40rem">
              <el-form-item label="关联资源品类名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="资源所属渠道名称">
                <el-popover placement="bottom" trigger="click" v-model="visible2" width="500">
                  <el-table
                    :data="tableData_So"
                    :row-class-name="tableRowClassName"
                    @row-dblclick="handledbClick_So"
                    max-height="250"
                    style="user-select:none"
                  >
                    <el-table-column label="资源所属渠道编号" property="id" width="100"></el-table-column>
                    <el-table-column label="资源所属渠道名称" property="name"></el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination
                      :current-page="currentPage_So"
                      :page-size="10"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="total_So"
                      @current-change="handleCurrentChange_So"
                      @size-change="handleSizeChange_So"
                      layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>
                  </div>
                  <el-input
                    @click.native="handleclick_So"
                    readonly
                    slot="reference"
                    v-model="form.soname"
                  ></el-input>
                </el-popover>
              </el-form-item>
              <el-form-item label="分成比例">
                <el-input v-model="form.share"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible2 = false">取 消</el-button>
            <el-button @click="addAssociated" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible3" title="提示" width="30%">
        <h2>即将导出xx条订单的xx条商品信息</h2>
        <el-checkbox style="margin-top:2rem;font-size:2rem" v-model="checked">将商品所属订单订单状态标记为生产中</el-checkbox>
        <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button @click="dialogVisible3 = false" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { regionDataPlus } from 'element-china-area-data'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      formInline: {
        user: '',
        region: '',
        workarea: []
      },
      //--------分割--------
      form: {
        name: '',
        soname: '',
        share: 0,
        channelId: ''
      },
      form1: {
        channelId: '',
        soname: '',
        share: 0,
        name: ''
      },

      updatapage: {
        page: 1,
        count: 10
      },
      updataId: '',
      total: 0,
      tableData: [],
      //关联表单
      visible1: false,
      visible2: false,
      //关联表单
      total_So: 0,
      tableData_So: [],
      currentPage_So: 1,
      updatapage_So: {
        page: 1,
        count: 10,
        valid: true
      },
      //--------分割--------
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      options: regionDataPlus,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      filesUpload: [],
      currentPage4: 1,
      checked: true,
      tableData2: [
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }
      ]
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!')
    },
    //清空表单
    clear() {
      this.form = { name: '', soname: '', channelId: '', share: 0 }
      this.form1 = { name: '', soname: '', channelId: '', share: 0 }
    },

    Submitin() {
      this.clear()
      this.dialogVisible2 = true
    },
    Submitout() {
      this.dialogVisible3 = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleChange(value) {
      console.log(value)
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },

    //--------分割--------
    //接口方法定义

    addAssociated() {
      console.log('新增关联资源品类')
      this.$api.search.addAssociated(this.form).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '新增成功',
            type: 'success',
            duration: 500
          })
          this.getAssociatedList()
          this.dialogVisible2 = false
        } else {
          this.$message({
            message: '新增失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    getAssociatedList() {
      console.log('获取关联资源品类列表')
      this.$api.search.getAssociatedList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total = data.total
          this.tableData = data.list
          console.log(this.tableData)
        }
      })
    },
    //翻页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.updatapage.page = val
      this.getAssociatedList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage.count = val
      this.getAssociatedList()
    },

    updataAssociated() {
      console.log('更新关联资源品类')
      this.$api.search.updataAssociated(this.updataId, this.form1).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 500
          })
          this.getAssociatedList()
          this.dialogVisible = false
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    updataStatuAssociated(row) {
      console.log('更新关联资源品类状态')
      let valid
      if (row.valid == true) {
        valid = false
      } else {
        valid = true
      }
      this.$api.search.updataStatuAssociated(row.id, valid).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          row.valid = valid
        } else {
          this.$message({
            message: '修改失败,请重试',
            type: 'error',
            duration: 500
          })
        }
      })
    },
    handleClick(row) {
      console.log(row)
      this.clear()
      this.updataId = row.id
      this.form1.id = row.id
      this.form1.name = row.name
      this.form1.soname = row.channel.name
      this.form1.channelId = row.channel.id
      this.form1.share = row.share
      this.dialogVisible = true
    },

    //渠道关联
    handleclick_So() {
      console.log('渠道选择')
      this.$api.search.getSourcesList(this.updatapage_So).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total_So = data.total
          this.tableData_So = data.list
        }
      })
    },
    handledbClick_So(row) {
      console.log(row, '渠道选中')
      this.form.soname = row.name
      this.form.channelId = row.id
      this.form1.soname = row.name
      this.form1.channelId = row.id
      this.visible1 = false
      this.visible2 = false
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.name === this.form.soname || row.name === this.form1.soname) {
        return 'success-row'
      }
      return ''
    },
    handleCurrentChange_So(val) {
      console.log(`当前页: ${val}`)
      this.updatapage_So.page = val
      this.handleclick()
    },
    handleSizeChange_So(val) {
      console.log(`每页 ${val} 条`)
      this.updatapage_So.count = val
      this.handleclick()
    },

    filterTag(value, row) {
      return row.valid === value
    }
    //--------分割--------
  },
  mounted() {
    this.getAssociatedList()
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.form1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
  }
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

/deep/ .success-row {
  background: #d9f1cb;
}
</style>

