<template>
  <!-- 物流信息 -->
  <div class="main">
    <div class="form">
      <el-form
        :inline="true"
        :model="updatapage"
        class="demo-form-inline"
        ref="updatapage"
        size="small"
      >
        <el-form-item label="订单号">
          <el-input placeholder="请输入订单号" v-model="updatapage.orderNo"></el-input>
        </el-form-item>
        <el-form-item label="快递公司">
          <el-select placeholder="快递公司" v-model="updatapage.expressCoId">
            <el-option
              :key="item.id"
              :label="item.name"
              :value="item.id"
              v-for="(item) in deliveryData"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运单号">
          <el-input placeholder="请输入运单号" v-model="updatapage.expressNo"></el-input>
        </el-form-item>

        <el-form-item label="收件人姓名">
          <el-input placeholder="收件人姓名" v-model="updatapage.receiverName"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号">
          <el-input placeholder="收件人手机号" v-model="updatapage.receiverMobile"></el-input>
        </el-form-item>
        <!-- change-on-select -->
        <el-form-item label="地区">
          <el-cascader
            :options="options"
            @change="handleChange"
            class="wd400"
            expand-trigger="hover"
            v-model="workarea"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input placeholder="详细地址" style="width:30rem" v-model="updatapage.addressDetail"></el-input>
        </el-form-item>
        <el-form-item v-if="$store.state.resourceCodeList.express_list">
          <el-button @click="onSubmit" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('updatapage')" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="button"></div>
    <div class="table">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="订单号" prop="orderNo" width="150"></el-table-column>
        <el-table-column label="快递公司" prop="expressCoName" width="80"></el-table-column>
        <el-table-column label="运单号" prop="expressNo" width="200"></el-table-column>
        <el-table-column label="收件人姓名" prop="receiver.name" width="120"></el-table-column>
        <el-table-column label="收件人手机号" prop="receiver.mobile" width="120"></el-table-column>
        <el-table-column label="省" prop="receiver.address.province" width="80"></el-table-column>
        <el-table-column label="市" prop="receiver.address.city" width="80"></el-table-column>
        <el-table-column label="区" prop="receiver.address.district" width="80"></el-table-column>
        <el-table-column label="详细地址" prop="receiver.address.detail"></el-table-column>
        <!-- <el-table-column label="创建时间" prop="zip" width="120"></el-table-column> -->
        <el-table-column label="发货时间" prop="sentTime" width="200"></el-table-column>
        <el-table-column label="订单状态" prop="state" width="120"></el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          v-if="$store.state.resourceCodeList.express_update"
          width="100"
        >
          <template slot-scope="scope">
            <el-button @click="handleDelivery(scope.row)" size="small" type="text">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage4"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div class="dialog">
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" title="提示">
        <el-table :data="tableData2" border height="250" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="订单编号" prop="date" width="180"></el-table-column>
          <el-table-column label="品类" prop="name" width="180"></el-table-column>
          <el-table-column label="规格" prop="address"></el-table-column>
          <el-table-column label="款式" prop="address"></el-table-column>
          <el-table-column label="型号" prop="address"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible2" title="提示">
        <el-upload
          :beforeUpload="beforeUpload"
          :file-list="filesUpload"
          :on-change="removeLastFile"
          :on-error="Onerror"
          accept=".xls, .xlsx"
          action="https://jsonplaceholder.typicode.com/posts/"
          class="upload-demo"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip" style="color:red">
            只支持Excel文件的导入,格式包括:xls, xlsx
            <br />单个导入的文件大小不能超过4M
          </div>
        </el-upload>
      </el-dialog>
      <el-dialog :before-close="handleClose" :visible.sync="dialogVisible3" title="提示" width="30%">
        <h2>即将导出xx条订单的xx条商品信息</h2>
        <el-checkbox style="margin-top:2rem;font-size:2rem" v-model="checked">将商品所属订单订单状态标记为生产中</el-checkbox>
        <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button @click="dialogVisible3 = false" type="primary">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible4"
        title="修改运单"
        width="50rem"
      >
        <div class="form1">
          <div>
            <el-form
              :model="hdelivery"
              label-position="left"
              label-width="10rem"
              ref="form"
              style="width:40rem"
            >
              <el-form-item label="快递公司" style="width:35rem">
                <el-select placeholder="快递公司" v-model="hdelivery.expressCoId">
                  <el-option
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    v-for="(item) in deliveryData"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="运单号" style="width:35rem">
                <el-input v-model="hdelivery.expressNo"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button @click="dialogVisible4 = false">取 消</el-button>
            <el-button @click="updataDelivery" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { regionDataPlus, CodeToText } from 'element-china-area-data'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      workarea: [],
      tableData: [],
      updatapage: {
        page: 1,
        count: 10
      },
      total: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      options: [],
      hdelivery: {
        expressCoId: '',
        expressNo: ''
      },
      hdelivery_id: '',
      dialogVisible4: false,
      deliveryData: [],

      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      filesUpload: [],
      currentPage4: 1,
      checked: true,
      tableData2: [],
      status: {
        SENT: '已发货',
        RECEIVED: '已签收'
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.updatapage.addressProvince) {
        delete this.updatapage.addressProvince
      }
      if (this.updatapage.addressCity) {
        delete this.updatapage.addressCity
      }
      if (this.updatapage.addressDistrict) {
        delete this.updatapage.addressDistrict
      }

      if (this.workarea) {
        let ssq = this.workarea.map(v => {
          return CodeToText[v]
        })
        ssq.forEach(v => {
          if (v.includes('省')) {
            this.updatapage.addressProvince = v
          } else if (v.includes('市') && v !== '市辖区') {
            this.updatapage.addressCity = v
          } else if (v.includes('区') && v !== '市辖区') {
            this.updatapage.addressDistrict = v
          }
        })
      }

      this.getLogistics()
    },
    resetForm(formName) {
      this.workarea = []
      this.updatapage = {
        page: 1,
        count: 10
      }
    },
    handleClick(row) {
      console.log(row)
      this.dialogVisible = true
    },
    Submitin() {
      this.dialogVisible2 = true
    },
    Submitout() {
      this.dialogVisible3 = true
    },
    handleDelivery(e) {
      this.hdelivery_id = e.id
      this.hdelivery.expressNo = e.expressNo
      this.hdelivery.expressCoId = e.expressCo.id
      this.dialogVisible4 = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },

    handleChange(value) {
      console.log(value)
    },
    Onerror(err) {
      console.log(err)
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10 //这里做文件大小限制
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
      }
      return extension || (extension2 && isLt2M)
    },
    removeLastFile(file, fileList) {
      this.filesUpload = fileList.slice(-1)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },

    //--------分割--------
    //接口方法定义
    getLogistics() {
      console.log('获取货品列表')
      this.$api.search.getLogistics(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.total = data.total
          this.tableData = data.list
          this.tableData.forEach(e => {
            e.state = this.status[e.state]
          })
        }
      })
    },

    //快递公司列表
    getCourierList() {
      console.log('获取快递公司列表')
      this.$api.search.getCourierList(this.updatapage).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          let data = res.data.data
          this.deliveryData = data.list
          console.log(this.deliveryData)
        }
      })
    },
    //修改发货接口
    updataDelivery() {
      this.$api.search
        .updataDelivery(this.hdelivery, this.hdelivery_id)
        .then(res => {
          console.log(res)
          if (res.status == 200 && res.data.code == 0) {
            this.$message({
              message: '修改发货成功',
              type: 'success',
              duration: 1000
            })
            this.getLogistics()
            this.dialogVisible4 = false
          } else {
            this.$message({
              message: '修改失败,请重试',
              type: 'error',
              duration: 1000
            })
            this.dialogVisible4 = false
          }
        })
    }
  },
  mounted() {
    this.options = regionDataPlus
    this.getLogistics()
    this.getCourierList()
  }
}
</script>
<style lang="scss" scoped>
.form .el-form {
  // background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.form1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
    /deep/ .el-input__inner {
      width: 25rem;
    }
  }
}

.button {
  // background-color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.table {
  margin-top: 1rem;
  background-color: #fff;
  /deep/ .cell {
    text-align: center;
  }
}

.block {
  background-color: #fff;
  border: 0.1rem solid #f0f2f5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
</style>
