<template>
  <div class="update">
    <div class="form">
      <el-container>
        <el-form
          :model="ruleFormUp"
          :rules="rules"
          class="demo-ruleForm"
          ref="ruleFormUp"
          status-icon
          style="width: 270px;"
        >
          <el-form-item label="旧密码" prop="pass">
            <el-input
              autocomplete="off"
              placeholder="旧密码"
              type="password"
              v-model="ruleFormUp.oldPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPass">
            <el-input
              autocomplete="off"
              placeholder="新密码"
              type="password"
              v-model="ruleFormUp.newPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="checkPass">
            <el-input
              @keyup.native.enter="submitForm('ruleFormUp')"
              autocomplete="off"
              placeholder="确认新密码"
              type="password"
              v-model="ruleFormUp.checkPass"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="submitForm('ruleFormUp')" type="primary">提交</el-button>
          </el-form-item>
        </el-form>
      </el-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.ruleFormUp.checkPass !== '') {
          this.$refs.ruleFormUp.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.ruleFormUp.newPassword) {
        callback(new Error('两次输入新密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      }
    }
    return {
      ruleFormUp: {
        oldPassword: '',
        newPassword: '',
        checkPass: ''
      },
      rules: {
        pass: [
          {
            validator: validatePass3,
            trigger: 'blur'
          }
        ],

        newPass: [
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ],
        checkPass: [
          {
            validator: validatePass2,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    submitForm() {
      //   this.$refs[formName].validate(valid => {
      //     if (valid) {
      delete this.ruleFormUp.checkPass
      this.$api.search.updatapassword(this.ruleFormUp).then(res => {
        if (res.status == 200) {
          if (res.data.code == 0) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 500
            })
            this.$store.commit('token', '')
            this.$store.commit('username', '')
            this.$store.commit('roleName', '')
            this.$store.commit('resourceCodeList', '')
            this.$router.push({
              path: '/',
              name: 'Login'
            })
          } else if (res.data.code == 110) {
            this.$message({
              message: '登录信息已失效,请重新登录!',
              type: 'error',
              duration: 500
            })
            this.$router.push({
              path: '/',
              name: 'Login'
            })
          } else {
            this.$message({
              message: '修改失败',
              type: 'error',
              duration: 500
            })
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.update {
  width: 100%;
  height: 830px;

  position: relative;
}

.update .el-container {
  width: 70%;
  height: 70%;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.update .el-form {
  margin: 0 auto;
}
</style>